import React from "react";
import { Table } from "reactstrap";

const EnrolledStudentsTable = (props) => {
  return (
    <React.Fragment>
      <Table striped bordered responsive>
        <thead>
          <td>
            <b>S.No</b>
          </td>
          <td>
            <b>Student Name</b>
          </td>
          <td>
            <b>View Internship Details</b>
          </td>
          <td>
            <b>View Documents</b>
          </td>
          <td>
            <b>View Profile</b>
          </td>
        </thead>
        <tbody>
          {props.data.map((data) => (
            <tr>
              <td>{data.id}</td>
              <td>{data.studentName}</td>
              <td>{data.viewInternshipDetails}</td>
              <td>{data.viewDocuments}</td>
              <td>{data.viewProfile}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default EnrolledStudentsTable;
