import React, { Component } from "react";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";

export default class ManualLeadForm extends Component {
  render() {
    return (
      <div className="page-content">
        <div className="p-2">
          <AvForm
            className="form-horizontal"
            onValidSubmit={() => console.log("submitted")}
          >
            <Card>
              <CardHeader>
                <h3>Manual Leads</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="10">
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <AvField
                          name="name"
                          type="text"
                          className="form-control"
                          id="name"
                          validate={{ required: true }}
                          placeholder="Enter Full Name"
                          errorMessage="This field is required"
                        />
                        <span className="registration_input-msg"></span>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </Label>
                      <Col md={10}>
                        <AvField
                          name="category"
                          type="text"
                          className="form-control"
                          id="category"
                          validate={{ required: true }}
                          placeholder="Enter Category"
                          errorMessage="This field is required"
                        />
                        <span className="registration_input-msg"></span>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Sub Category
                      </Label>
                      <Col md={10}>
                        <AvField
                          name="subCategory"
                          type="text"
                          className="form-control"
                          id="subCategory"
                          validate={{ required: true }}
                          placeholder="Enter Sub Category"
                          errorMessage="This field is required"
                        />
                        <span className="registration_input-msg"></span>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Email
                      </Label>
                      <Col md={10}>
                        <AvField
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          validate={{ required: true, email: true }}
                          placeholder="Enter Email"
                          errorMessage="Please provide a valid email"
                        />
                        <span className="registration_input-msg"></span>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Phone No.
                      </Label>
                      <Col md={10}>
                        <AvField
                          name="phnNo"
                          type="tel"
                          className="form-control"
                          id="phnNo"
                          validate={{ required: true, tel: true }}
                          placeholder="Enter Phone No."
                          errorMessage="Please provide a valid phone number"
                        />
                        <span className="registration_input-msg"></span>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Service
                      </Label>
                      <Col md={10}>
                        <AvField
                          name="service"
                          type="text"
                          className="form-control"
                          id="service"
                          validate={{ required: true }}
                          errorMessage="This field is required"
                          placeholder="Enter Services"
                        />
                        <span className="registration_input-msg"></span>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <div className="mt-2 ">
              <Button
                className="skill-btn w-md waves-effect waves-light"
                type="submit"
              >
                Submit
              </Button>
            </div>

            {/* <div className="mt-4 text-center">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock mr-1"></i> Forgot
                                  your password?
                                </Link>
                              </div> */}
          </AvForm>
        </div>
      </div>
    );
  }
}
