import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  CustomInput,
  InputGroup,
  Spinner,
  Form,
  InputGroupAddon,
  CardHeader,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";
import { notification, message } from "antd";
import { putPermisionAPI } from "../../helpers/ApiManager/permissions";
import { initFetchServices, initFetchAdmins } from "../../store/actions";

class Permission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "Form Advanced", link: "#" },
      ],
      selectedGroup: null,
      selectedMulti: null,
      formLoader: false,
    };
  }

  componentDidMount() {
    this.props.fetchServices();
    this.props.fetchAdmins();
  }

  handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };
  updateDetail = async (values) => {
    this.setState({
      formLoader: true,
    });
    let keywords = "";
    if (this.state.selectedMulti !== null) {
      this.state.selectedMulti.map((itm) => {
        keywords += itm.label + ",";
      });
    }
    values.services_available_view = keywords;
    Object.keys(values).map((key) => {
      if (key != "services_available_view") {
        values[key] = parseInt(values[key]);
      } else {
        values[key] = values[key].slice(0, -1);
      }
    });

    try {
      const res = await putPermisionAPI(
        "/super_admin/add_authority_permission",
        JSON.stringify(values)
      );
      message.success(res.message);
      this.form.reset();
      this.setState({
        formLoader: false,
      });
    } catch (err) {
      this.setState({
        formLoader: false,
      });
      console.log(err);
    }
  };
  handleSubmit = (event, values) => {
    this.updateDetail(values);
  };

  render() {
    const { selectedMulti } = this.state;
    const optionGroup = [
      {
        options: this.props.serviceState.services.map((itm) => ({
          label: itm.name,
          value: itm.id,
        })),
      },
    ];

    const admins = this.props.adminsState.admins.map((data) => {
      return {
        id: data.id,
        name: data.name,
      };
    });

    return (
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardHeader>
              <h4 className="">Update Permission</h4>
            </CardHeader>
            <CardBody>
              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
              >
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="tenthboard">Select Sub Admin</Label>
                      <AvField
                        name="authority_id"
                        type="select"
                        errorMessage="Select Sub Admin"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="subAdmin"
                      >
                        <option value={1}>Select</option>
                        {admins.map((admin) => (
                          <option key={admin.id} value={admin.id}>
                            {admin.name}
                          </option>
                        ))}
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="employment_type">Create Service</Label>
                      <AvField
                        name="service_creation"
                        type="select"
                        errorMessage="Enter Employment Type"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="service_creation"
                      >
                        <option>select</option>
                        <option value={1}>True</option>
                        <option value={0}>False</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="employment_type">Update Service</Label>
                      <AvField
                        name="service_update"
                        type="select"
                        errorMessage="Enter Response"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="service_update"
                      >
                        <option>select</option>
                        <option value={1}>True</option>
                        <option value={0}>False</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="employment_type">View Service</Label>
                      <AvField
                        name="service_view"
                        type="select"
                        errorMessage="Enter Response"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="service_view"
                      >
                        <option>select</option>
                        <option value={1}>True</option>
                        <option value={0}>False</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="employment_type">View lead</Label>
                      <AvField
                        name="lead_view"
                        type="select"
                        errorMessage="Enter Response"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="lead_view"
                      >
                        <option>select</option>
                        <option value={1}>True</option>
                        <option value={0}>False</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="employment_type">
                        Update status of leads
                      </Label>
                      <AvField
                        name="lead_status_update"
                        type="select"
                        errorMessage="Enter Response"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="lead_status_update"
                      >
                        <option>select</option>
                        <option value={1}>True</option>
                        <option value={0}>False</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="employment_type">View Commission</Label>
                      <AvField
                        name="commission_view"
                        type="select"
                        errorMessage="Enter Response"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="commision_view"
                      >
                        <option>select</option>
                        <option value={1}>True</option>
                        <option value={0}>False</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="employment_type">Service access</Label>
                      <Select
                        menuPlacement="top"
                        value={selectedMulti}
                        isMulti={true}
                        onChange={this.handleMulti}
                        options={optionGroup}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Button color="success" type="submit">
                  {this.state.formLoader ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceState: state.serviceReducer,
    adminsState: state.adminReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchServices: () => dispatch(initFetchServices()),
    fetchAdmins: () => dispatch(initFetchAdmins()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Permission);
