import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import "./dashboard.scss";

const TotalStudentsEnrolled = (props) => {
  return (
    <>
      <Col md={4}>
        <Card>
          <CardBody>
            <Row>
              <Col md={8}>
                <h4 className="card-title">Total Students Enrolled</h4>
              </Col>
              <Col md={4}>
                <div style={{textAlign: "right"}}>
                  <h5>{props.studentEnrolled.noOfStudents}</h5>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default TotalStudentsEnrolled;
