import React, { Component } from "react";
import { Container, Row } from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import TotalStudentsEnrolled from "./TotalStudentsEnrolled";
import TotalMentorsEnrolled from "./TotalMentorsEnrolled";
import TotalMsmeEnrolled from "./TotalMsmeEnrolled";
import TotalOngoingInternship from "./TotalOngoingInternship";
import TotalNoOfServices from "./TotalNoOfServices";
import ServicesAvailedThisMonth from "./ServicesAvailedThisMonth";
import RevenueThisMonth from "./RevenueThisMonth";
import TotalServicesAvailed from "./TotalServicesAvailed";
import TotalRevenue from "./TotalRevenue";

import "./dashboard.scss";
import "./datatables.scss";


// var URL = "http://34.214.207.222:80/api/"; 

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_large: false,
      breadcrumbItems: [
        { title: "EGS", link: "#" },
        { title: "Dashboard", link: "#" },
      ],
    };
  }

  // useEffect(()=>{
  //          fetch({URL})
  // });

  render() {
    const studentEnrolled = [
      {
        noOfStudents: 25,
      },
    ];

    const mentorEnrolled = [
      {
        noOfMentors: 25,
      },
    ];

    const msmeEnrolled = [
      {
        noOfMsme: 25,
      },
    ];

    const ongoingInternship = [
      {
        noOfInternship: 50,
      },
    ];

    const noOfServices = [
      {
        noOfServices: 5,
      },
    ];

    const serviceAvailedThisMonth = [
      {
        noOfServices: 2,
      },
    ];

    const revenueThisMonth = [
      {
        revenue: 5000,
      },
    ];

    const totalServicesAvailed = [
      {
        service: 50,
      },
    ];

    const totalRevenue = [
      {
        revenue: 20000,
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <TotalStudentsEnrolled studentEnrolled={studentEnrolled[0]} />
              {/* <TotalMentorsEnrolled mentorEnrolled={mentorEnrolled[0]}/>
              <TotalMsmeEnrolled msmeEnrolled={msmeEnrolled[0]}/> */}
              {/* <TotalOngoingInternship ongoingInternship={ongoingInternship[0]}/> */}
              <TotalNoOfServices noOfServices={noOfServices[0]}/>
              <ServicesAvailedThisMonth serviceAvailed={serviceAvailedThisMonth[0]}/>
              <RevenueThisMonth revenue={revenueThisMonth[0]}/>
              <TotalServicesAvailed totalService={totalServicesAvailed[0]}/>
              <TotalRevenue totalRevenue={totalRevenue[0]}/>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
