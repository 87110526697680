import {
  INIT_FETCH_ADMINS,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
} from "./actionTypes";

const initFetchAdmins = () => {
  return {
    type: INIT_FETCH_ADMINS,
  };
};

const fetchAdminSuccess = (admins) => {
  return {
    type: FETCH_ADMINS_SUCCESS,
    payload: admins,
  };
};

const fetchAdminFailure = (error) => {
  return {
    type: FETCH_ADMINS_FAILURE,
    payload: error,
  };
};

export { initFetchAdmins, fetchAdminFailure, fetchAdminSuccess };
