import React from "react";
import { Row, Col, Table } from "reactstrap";
import "./msme.css";

const MsmeDetails = (props) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <div className="profile-details-sec">
            <div className="student-img">
              <img src={props.data.msmeImg}></img>
            </div>
            <h4>{props.data.msmeName}</h4>
            <h5>{props.data.msmeEmail}</h5>
            <h5>{props.data.msmePhn}</h5>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MsmeDetails;
