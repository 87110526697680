import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { message } from "antd";
// Login Redux States
import { UPLOADCSV_REQUEST_INIT } from "./actionTypes";
import { uploadSuccess, uploadInit, uploadFailure } from "./action";

// AUTH related methods

import { csvUpload } from "../../helpers/ApiManager/subAdminRegister";

function* handleCsvUpload(user) {
  console.log(user);
  try {
    if (user.payload.category == "0") {
      const response = yield call(csvUpload, "/super_admin/register_student", {
        student_file: user.payload.csvFile,
      });
      message.success(response.message);
    } else if (user.payload.category == "1") {
      const response = yield call(
        csvUpload,
        "/super_admin/register_working_professional",
        {
          working_professional_file: user.payload.csvFile,
        }
      );
      message.success(response.message);
    } else {
      const response = yield call(csvUpload, "/super_admin/register_MSME", {
        MSME_file: user.payload.csvFile,
      });
      message.success(response.message);
    }

    yield put(uploadSuccess("response"));
  } catch (error) {
    yield put(uploadFailure(error));
  }
}

export function* watchCsvUpload() {
  yield takeEvery(UPLOADCSV_REQUEST_INIT, handleCsvUpload);
}

function* uploadcsvSaga() {
  yield all([fork(watchCsvUpload)]);
}

export default uploadcsvSaga;
