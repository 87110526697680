import React from "react";
import { Table } from "reactstrap";

const EnrolledMentorsTable = (props) => {
  return (
    <React.Fragment>
      <Table striped bordered responsive>
        <thead>
          <td>
            <b>S.No</b>
          </td>
          <td>
            <b>Mentor Name</b>
          </td>
          <td>
            <b>Mentor Details</b>
          </td>
          <td>
            <b>Enrolled Students</b>
          </td>
        </thead>
        <tbody>
          {props.data.map((data) => (
            <tr>
              <td>{data.id}</td>
              <td>{data.mentorName}</td>
              <td>{data.viewDetails}</td>
              <td>{data.enrolledStudents}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default EnrolledMentorsTable;
