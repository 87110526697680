import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
  Card,
  CardBody,
  Input,
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  registerInit,
  registerUserFailed,
  apiError,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import egslogo from "../../assets/images/egs-logo.png";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      category: "0",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, values) {
    console.log(values);
    this.props.registerInit(values);
  }

  handleSelect = (e) => {
    this.setState({
      category: e.target.value,
    });
  };

  componentDidMount() {
    this.props.registerUserFailed("");
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  render() {
    const renderSwitch = () => {
      switch (this.state.category) {
        case "0":
          return <></>;
        case "1":
          return student;
        case "2":
          return wp;
        case "3":
          return be;
        default:
          return student;
      }
    };

    const student = (
      <>
        <Row>
          <Col md="4">
            <div className="form-check mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
              />
              <Label className="form-check-label" htmlFor="defaultCheck1">
                Be An Intern
              </Label>
            </div>
          </Col>
          <Col md="4">
            <div className="form-check mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
              />
              <Label className="form-check-label" htmlFor="defaultCheck1">
                Other Career Services
              </Label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <AvField
              type="text"
              name="select"
              label="College/University Name"
              placeholder="College/University Name"
            ></AvField>
          </Col>
          <Col md="4">
            <AvField
              type="text"
              name="select"
              label="Current Location"
              placeholder="Current Location & Pincode"
            ></AvField>
          </Col>
          <Col md="4">
            <AvField type="select" name="select" label="Highest Qualification">
              <option>Select Qualification</option>
              <option>12th</option>
              <option>UG</option>
              <option>PG</option>
              <option>PhD</option>
              <option>Others</option>
            </AvField>
          </Col>
        </Row>
      </>
    );
    const wp = (
      <>
        <Row>
          <Col md="4">
            <div className="form-check mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
              />
              <Label className="form-check-label" htmlFor="defaultCheck1">
                Mentor An Intern
              </Label>
            </div>
          </Col>
          <Col md="4">
            <div className="form-check mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
              />
              <Label className="form-check-label" htmlFor="defaultCheck1">
                Other Professional Services
              </Label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <AvField
              type="text"
              name="select"
              label="Current Designation"
              placeholder="Current Designation"
            ></AvField>
          </Col>
          <Col md="4">
            <AvField
              type="text"
              name="select"
              label="Current Location"
              placeholder="Current Location & Pincode"
            ></AvField>
          </Col>
          <Col md="4">
            <AvField
              type="text"
              name="select"
              label="Current Org / Function"
              placeholder="Current Org / Function"
            ></AvField>
          </Col>
        </Row>
      </>
    );

    const be = (
      <>
        <Row>
          <Col md="4">
            <div className="form-check mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
              />
              <Label className="form-check-label" htmlFor="defaultCheck1">
                Map An Intern
              </Label>
            </div>
          </Col>
          <Col md="4">
            <div className="form-check mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
              />
              <Label className="form-check-label" htmlFor="defaultCheck1">
                Other Business Services
              </Label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <AvField type="select" name="select" label="Business Category">
              <option>Select Business Category</option>
              <option>Manufacturing</option>
              <option>Trading</option>
              <option>Services</option>
            </AvField>
          </Col>
          <Col md="4">
            <AvField
              type="text"
              name="select"
              label="Current Location"
              placeholder="Current Location & Pincode"
            ></AvField>
          </Col>
          <Col md="4">
            <AvField type="select" name="select" label="Business Type">
              <option>Select Business Type</option>
              <option>Prop</option>
              <option>Partnership</option>
              <option>LLP</option>
              <option>Private Ltd</option>
              <option>Public Ltd</option>
              <option>NGO</option>
              <option>Others</option>
            </AvField>
          </Col>
        </Row>
      </>
    );
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0 login-screen">
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={8}>
                        <div className="login-card">
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={egslogo} width="130" alt="logo" />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Register Your Account With{" "}
                              <span style={{ color: "#00a0e3" }}>Skill</span>
                              <span style={{ color: "#f29440" }}>Labs</span>
                            </h4>
                          </div>

                          {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null}

                          <div className="p-2">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleSubmit}
                            >
                              <Row>
                                <Col md="4">
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="name">Name</Label>
                                    <AvField
                                      name="name"
                                      // value={this.state.username}
                                      type="text"
                                      className="form-control"
                                      id="name"
                                      validate={{ required: true }}
                                      placeholder="Enter Full Name"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="Email">Email</Label>
                                    <AvField
                                      name="username"
                                      // value={this.state.username}
                                      type="email"
                                      className="form-control"
                                      id="username"
                                      validate={{ email: true, required: true }}
                                      placeholder="Enter Email Id"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="4">
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="phnno">Contact No.</Label>
                                    <AvField
                                      name="phnno"
                                      // value={this.state.username}
                                      type="tel"
                                      className="form-control"
                                      id="phnno"
                                      validate={{ tel: true, required: true }}
                                      placeholder="Enter Contact Number"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="4">
                                  <AvField
                                    type="select"
                                    name="select"
                                    label="Select Category"
                                    onChange={this.handleSelect}
                                  >
                                    <option value="0">Select category</option>
                                    <option value="1">Student</option>
                                    <option value="2">
                                      Working Professional
                                    </option>
                                    <option value="3">
                                      Business Enterprise
                                    </option>
                                  </AvField>
                                </Col>
                              </Row>
                              {renderSwitch()}

                              <div className="mt-4 text-center">
                                <Button
                                  className="skill-btn w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>

                              {/* <div className="mt-4 text-center">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock mr-1"></i> Forgot
                                  your password?
                                </Link>
                              </div> */}
                            </AvForm>
                          </div>

                          <div
                            className="text-center"
                            style={{ paddingTop: "10px" }}
                          >
                            <p>
                              Already a User ?{" "}
                              <Link
                                to="/login"
                                className="font-weight-medium register-link-text"
                              >
                                {" "}
                                Login{" "}
                              </Link>{" "}
                            </p>
                            <p>© 2022 EGS.</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/* <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerInit,
  apiError,
  registerUserFailed,
})(Register);
