import {
  UPLOADCSV_REQUEST_INIT,
  UPLOADCSV_REQUEST_SUCCESS,
  UPLOADCSV_REQUEST_FAILURE,
} from "./actionTypes";

const uploadInit = (user) => {
  return {
    type: UPLOADCSV_REQUEST_INIT,
    payload: user,
  };
};

const uploadSuccess = () => {
  return {
    type: UPLOADCSV_REQUEST_SUCCESS,
  };
};

const uploadFailure = (error) => {
  return {
    type: UPLOADCSV_REQUEST_FAILURE,
    payload: error,
  };
};

export { uploadInit, uploadSuccess, uploadFailure };
