import React, { useEffect, useState } from "react";
import { Table, Row, Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { api_route } from "../../../Api-config/config";



const ConvertedLeads = (props) => {
  const loaddata = props.getConvertedData;
  const history = useHistory();

  const [incr, setincr] = useState(-1);
  const [status, setstatus] = useState("");
  const [assigned_to, setassigned_to] = useState("");
  const [rem1, setrem1] = useState("");
  const [rem2, setrem2] = useState("");
  const [revenue, setrevenue] = useState("");

  const categoryOption = [
    {
      options: [
        { label: "Student", value: "Student" },
        // { label: "Mentor", value: "Mentor" },
        // { label: "MSME", value: "MSME" },
      ],
    },
  ];

  const serviceOption = [
    {
      options: [
        { label: "Admission", value: "Admission" },
        { label: "Course", value: "Course" },
      ],
    },
  ];


  const saveApiCall = (id) => {

    const data = {
      // updatedata
      "lead_id": id,
      "authority_id": 1,
      "status": 3,
      "remark1": rem1,
      "remark2": loaddata.data[id].remark2,
      "remark3": "default",
      "revenue": revenue


    }

    // console.log('====================================');
    // console.log(data);
    // console.log('====================================');

    fetch(api_route + '/super_admin/assign_lead', {
      method: 'PUT',
      headers: {
        "Content-type": "application/json",
        "access-token": localStorage.getItem("token"),
        "Access-Control-Allow-Origin": '*'
      },
      body: JSON.stringify(data)

    }).then(response => {
      if (response.status === 200) {
        // window.alert("Invalid REgisteration ");
        alert("Changes Saved")
        window.location.reload(true);
        history.push("/leadManagement/");
        // history.push("/dashboard");
      }
      else {
        alert("Some Error Occured");
      }
      return response.json()
    }).then(json => {
      // console.log(json)
      // setUpdatedata();
      // this.setState({
      // 	user:json
      // });
    }
      // var history = useHistory();


    );
  }






  // const url = "http://127.0.0.1:80/api";

  // const [loaddata, setloaddata] = useState([
  // {
  //   id: 1,
  //   name: "Ashutosh",
  //   category: "Student",
  //   email: "Ashu@gmail.com",
  //   phn: 9898659568,
  //   service: "Admission",
  //   status: "Converted",
  //   assignedTo: "Ashu",
  //   remark1: "Remark 1",
  //   remark2: "Remark 2",
  //   revenue: "Revenue"
  // },
  // {
  //   id: 2,
  //   name: "Amit",
  //   category: "Mentor",
  //   email: "Amit@gmail.com",
  //   phn: 9898659568,
  //   service: "Admission",
  //   status: "Converted",
  //   assignedTo: "Aman",
  //   remark1: "Remark 1",
  //   remark2: "Remark 2",
  //   revenue: "Revenue"
  // },
  // ]

  // );

  // useEffect(() => {
  //   const filterdata = loaddata.filter(item => item.status !== "Pending");
  //   setloaddata(filterdata);
  // })


  // useEffect(() => {
  //   fetch({ url } + "/super_admin/get_leads")
  //     .then(res => {
  //       if (!res.ok) { // error coming back from server
  //         throw Error('could not fetch the data for that resource');
  //       }
  //       return res.json();
  //     })
  //     .then(data => {
  //       //                         setIsPending(false);
  //       const filterdata = data.filter(item => item.status ===2  );  //value for converted
  //       setloaddata(filterdata);
  //       // console.log(filterdata);
  //       //                         setError(null);
  //     })
  //     .catch(err => {
  //       //                         // auto catches network / connection error
  //       //                         setIsPending(false);
  //       //                         setError(err.message);
  //       console.log(err);
  //     })
  // });


  // const leadsData = [
  // {
  //   id: 1,
  //   name: "Ashutosh",
  //   category: "Student",
  //   email: "Ashu@gmail.com",
  //   phn: 9898659568,
  //   service: "Admission",
  //   status: "Converted",
  //   assignedTo: "Ashu",
  //   remark1: "Remark 1",
  //   remark2: "Remark 2",
  //   revenue: "Revenue"
  // },
  // {
  //   id: 2,
  //   name: "Amit",
  //   category: "Mentor",
  //   email: "Amit@gmail.com",
  //   phn: 9898659568,
  //   service: "Admission",
  //   status: "Converted",
  //   assignedTo: "Aman",
  //   remark1: "Remark 1",
  //   remark2: "Remark 2",
  //   revenue: "Revenue"
  // },
  // ];
  const auth_name = {
    0: "None",
    1: "Dr. Piyush Bhardwaj",
    2: "Dr. Adarsh Bhardwaj",
    3: "Mr. Bikash Sinha",
    4: "Mr. Yash Sharma",
    5: "Ms. Monimala",
    6: "Ms. Shubha Sharma",
    7: "Mr. B.P. Arora",
  };
  // const auth_name = {
  //   0: "None",
  //   1: "Dr. Piyush Bhardwaj",
  //   2: "Dr. Adarsh Bhardwaj",
  //   3: "Mr. Bikash Sinha",
  //   4: "Mr. Yash Sharma",
  //   5: "Ms. Monimala",
  //   6: "Ms. Shubha Sharma",
  //   7: "Mr. B.P. Arora",
  // };


  return (
    <>
      <div className="filtered-fields">
        <Row>
          <Col md={8}>
            <Row>
              <Col md={4}>
                <FormGroup className="select2-container">
                  <Label>Select Category</Label>
                  <Select
                    options={categoryOption}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className="select2-container">
                  <Label>Select Service</Label>
                  <Select
                    options={serviceOption}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Country</th>
            <th>E-Mail</th>
            <th>Phone No</th>
            <th>Service</th>
            <th>Status</th>
            <th>Assigned To</th>
            <th>Remark 1</th>
            <th>Remark 2</th>
            <th>Revenue</th>
          </tr>
        </thead>
        <tbody>
          {/* {
            (loaddata.data) ?

              loaddata.data.map((loaditem) => (
                (loaditem.assigned_to !== null && loaditem.status===3 )  ?
                  <tr>
                    <td>{loaditem.id}</td>
                    <td>{loaditem.user_name}</td>
                    <td>{loaditem.user_category}</td>
                    <td>{loaditem.user_email}</td>
                    <td>{loaditem.user_phone_number}</td>
                    <td>{loaditem.service_name}</td>
                    <td>{loaditem.status}</td>
                    <td>{loaditem.assigned_to}</td>
                    <td>{loaditem.remark1}</td>
                    <td>{loaditem.remark2}</td>
                    <td  >{loaditem.revenue}</td>
                    <td   >
                  <input  defaultValue={loaditem.revenue}  onChange={(e) => setrevenue(e.target.value)} />
                  
                  </td>
                  </tr>
                  :
                  console.log(loaditem.assigned_to)
              ))
              :

              console.log("nodata")

          } */}

          {
            (loaddata.data) &&

            loaddata.data.slice(0).reverse().map((loaditem, index) => (
              (loaditem.status === 3) &&
              <tr>
                <td >{loaditem.id}</td>
                <td>{loaditem.user_name}</td>
                <td>{loaditem.country}</td>
                <td>{loaditem.user_email}</td>
                <td>{loaditem.user_phone_number}</td>
                <td>{loaditem.service_name}</td>

                <td>Converted</td>
                <td> {auth_name[loaditem.assigned_to]} </td>
                <td >

                  <input defaultValue={loaditem.remark1} onChange={(e) => setrem1(e.target.value)} />

                </td>

                {/* <td contentEditable="true" onChange={(e) => setrem2(e.target.value)} > */}
                <td   >
                  <input defaultValue={loaditem.remark2} onChange={(e) => setrem2(e.target.value)} />

                </td>
                <td   >
                  <input defaultValue={loaditem.revenue} onChange={(e) => setrevenue(e.target.value)} />

                </td>


                {/* <td   >
                  <input   Value={loaditem.remark3}  onChange={(e) => setrem3(e.target.value)} />
                  
                  </td> */}
                <td  ><button onClick={() => saveApiCall(loaditem.id)} >Save</button>
                </td>
                {/* <td>{loaditem.remark2}</td>
                  <td>{loaditem.remark3}</td> */}
              </tr>
            ))


          }
        </tbody>
      </Table>
    </>
  );
};

export default ConvertedLeads;
