import React, { Component } from "react";
import {
  Spinner,
  Col,
  Row,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";
import { api_route } from "../../../Api-config/config";
import { message, notification } from "antd";
import { connect } from "react-redux";
import { initFetchCategories } from "../../../store/actions";
import {
  addCategory,
  postService,
} from "../../../helpers/ApiManager/categories";

class CreateService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_large: false,
      modal_category: false,
      categories: [],
      subcategories: [],
      type: "",
      formLoader: false,
    };
    this.tog_large = this.tog_large.bind(this);
    this.tog_category = this.tog_category.bind(this);
  }

  componentDidMount() {
    this.fetchCategories();
    this.fetchSubCategories();
  }

  submitNewCategory = async (event, values) => {
    let obj = {};
    obj.name = values.Category;
    obj.type = parseInt(this.state.type);

    try {
      const categoryRes = await addCategory(
        "/super_admin/add_category_or_sub_category",
        obj
      );
      message.success(categoryRes.message);
      this.state.type == 1 ? this.fetchCategories() : this.fetchSubCategories();
      this.tog_category();
    } catch (err) {
      this.tog_category();
      console.log(err);
    }

    // fetch(api_route + "/super_admin/add_category_or_sub_category", {
    //   method: "POST",
    //   headers: {
    //     "access-token": localStorage.getItem("token"),
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(obj),
    // })
    //   .then((response) => {
    //     if (response.status >= 200 && response.status <= 299) {
    //       response.json().then((data) => {
    //         message.success(data.message);
    //         this.fetchCategories();
    //         this.tog_category();
    //       });
    //     } else {
    //       switch (response.status) {
    //         case 404:
    //           notification.error({
    //             message:
    //               "Sorry! the page you are looking for could not be found",
    //           });

    //           break;
    //         case 500:
    //           notification.error({
    //             message:
    //               "Sorry! something went wrong, please contact our support team",
    //           });
    //           break;
    //         case 401:
    //           notification.error({ message: "Invalid Token Login again" });
    //           localStorage.removeItem("token");
    //           window.location.reload(false);

    //           break;
    //         default:
    //           response.json().then((data) => {
    //             notification.error({
    //               message: data.error !== "" ? data.error : "Error occurred",
    //             });
    //           });
    //           break;
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  fetchCategories = () => {
    this.props.fetchCategoriesrdx();

    // fetch(api_route + "/super_admin/get_category_or_sub_category?type=1", {
    //   method: "GET",
    //   headers: {
    //     "access-token": localStorage.getItem("token"),
    //   },
    // })
    //   .then((response) => {
    //     if (response.status >= 200 && response.status <= 299) {
    //       response.json().then((data) => {
    //         this.setState({
    //           categories: data.data,
    //         });
    //       });
    //       // message.success("User fetched Successfully");
    //       // this.form && this.form.reset();
    //     } else {
    //       switch (response.status) {
    //         case 404:
    //           notification.error({
    //             message:
    //               "Sorry! the page you are looking for could not be found",
    //           });
    //           break;
    //         case 500:
    //           notification.error({
    //             message:
    //               "Sorry! something went wrong, please contact our support team",
    //           });
    //           break;
    //         case 401:
    //           notification.error({ message: "Invalid Token Login again" });
    //           localStorage.removeItem("token");
    //           window.location.reload(false);
    //           break;
    //         default:
    //           response.json().then((data) => {
    //             notification.error({
    //               message: data.error !== "" ? data.error : "Error occurred",
    //             });
    //           });
    //           break;
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  fetchSubCategories = () => {
    fetch(api_route + "/super_admin/get_category_or_sub_category?type=2", {
      method: "GET",
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          response.json().then((data) => {
            this.setState({
              subcategories: data.data,
            });
          });
          // message.success("User fetched Successfully");
          // this.form && this.form.reset();
        } else {
          switch (response.status) {
            case 404:
              notification.error({
                message:
                  "Sorry! the page you are looking for could not be found",
              });

              break;
            case 500:
              notification.error({
                message:
                  "Sorry! something went wrong, please contact our support team",
              });
              break;
            case 401:
              notification.error({ message: "Invalid Token Login again" });
              localStorage.removeItem("token");
              window.location.reload(false);

              break;
            default:
              response.json().then((data) => {
                notification.error({
                  message: data.error !== "" ? data.error : "Error occurred",
                });
              });
              break;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  tog_category(type) {
    this.setState((prevState) => ({
      modal_category: !prevState.modal_category,
      type: type,
    }));
    this.removeBodyCss();
  }

  tog_large() {
    this.setState((prevState) => ({
      modal_large: !prevState.modal_large,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  handleSubmit = async (event, values) => {
    try {
      this.setState({ formLoader: true });
      const res = await postService("/api/users/create_lead", values);
      // console.log(res);
      this.setState({ formLoader: false });
      message.success(res.message);
    } catch (error) {
      this.setState({ formLoader: false });
      console.log(error);
    }
  };

  render() {
    const institution = [
      {
        options: [
          { label: "BPIT", value: "BPIT" },
          { label: "MAIT", value: "MAIT" },
          { label: "IIT", value: "IIT" },
        ],
      },
    ];

    const category = [
      {
        options: this.props.categoryState.values.map((category) => ({
          label: category.name,
          value: category.name,
        })),
      },
    ];

    const subcategory = [
      {
        options: this.state.subcategories.map((category) => ({
          label: category.name,
          value: category.name,
        })),
      },
    ];

    return (
      <React.Fragment>
        <AvForm className="needs-validation" onValidSubmit={this.handleSubmit}>
          <Row>
            <Col md="5">
              <FormGroup className="select2-container">
                <Label>Category</Label>
                <AvField
                  name="category_id"
                  placeholder="Category"
                  type="select"
                  errorMessage="Enter Category"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="validationCustom02"
                >
                  <option>Select Category</option>
                  {this.props.categoryState.values.map((itm, idx) => (
                    <option value={itm.id} key={idx}>
                      {itm.name}
                    </option>
                  ))}
                </AvField>
              </FormGroup>
            </Col>
            <Col md="1">
              <FormGroup>
                <Label>Add</Label>
                <button
                  onClick={() => this.tog_category(1)}
                  className="btn btn-danger"
                >
                  <i className="ri-add-line"></i>
                </button>
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <Label htmlFor="validationCustom02">Sub-Category</Label>
                <AvField
                  name="sub_category_id"
                  placeholder="Sub Category"
                  type="select"
                  errorMessage="Enter Sub Category"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="validationCustom02"
                >
                  <option>Select Sub Category</option>
                  {this.state.subcategories.map((itm, idx) => (
                    <option value={itm.id} key={idx}>
                      {itm.name}
                    </option>
                  ))}
                </AvField>
              </FormGroup>
            </Col>
            <Col md="1">
              <FormGroup>
                <Label>Add</Label>
                <button
                  onClick={() => this.tog_category(2)}
                  className="btn btn-danger"
                >
                  <i className="ri-add-line"></i>
                </button>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="validationCustom01">Service Name</Label>
                <AvField
                  name="name"
                  placeholder="Service Name"
                  type="text"
                  errorMessage="Enter Service Name"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="name"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="validationCustom02">Description</Label>
                <AvField
                  name="description"
                  placeholder="Description"
                  type="text"
                  errorMessage="Enter Description"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="validationCustom02"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <FormGroup className="select2-container">
                <Label>Institution / Organisation</Label>
                <AvField
                  name="institute"
                  placeholder="Institution / Organisation"
                  type="select"
                  errorMessage="Enter Institution / Organisation"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="validationCustom02"
                >
                  <option>Select Institution / Organisation</option>
                  {[
                    { label: "BPIT", value: "BPIT" },
                    { label: "MAIT", value: "MAIT" },
                    { label: "IIT", value: "IIT" },
                  ].map((itm, idx) => (
                    <option value={itm.label} key={idx}>
                      {itm.label}
                    </option>
                  ))}
                </AvField>
              </FormGroup>
            </Col>
            <Col md="1">
              <FormGroup>
                <Label>Add </Label>
                <button onClick={this.tog_large} className="btn btn-danger">
                  <i className="ri-add-line"></i>
                </button>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label htmlFor="validationCustom04">Commission</Label>
                <AvField
                  name="commision"
                  placeholder="Enter Amount"
                  type="number"
                  errorMessage="Please provide a Commission"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="validationCustom04"
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="success" type="submit">
            {this.state.formLoader ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              "Add Service"
            )}
          </Button>
        </AvForm>

        {/* Modal for Add Category */}
        <Modal
          size="sm"
          isOpen={this.state.modal_category}
          toggle={this.tog_category}
        >
          <ModalHeader toggle={() => this.setState({ modal_category: false })}>
            {this.state.type == 1 ? "Add Category " : "Add Sub Category"}
          </ModalHeader>
          <ModalBody>
            <AvForm
              className="needs-validation"
              onValidSubmit={this.submitNewCategory}
            >
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="validationCustom02">
                      {this.state.type == 1
                        ? "Category Name "
                        : "Sub Category Name"}
                    </Label>
                    <AvField
                      name="Category"
                      placeholder="Category"
                      type="text"
                      errorMessage="Enter Category Name"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom02"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="info" type="submit">
                Add
              </Button>
            </AvForm>
          </ModalBody>
        </Modal>
        {/* Modal ENDS for Add Organisation */}

        {/* Modal for Add Organisation */}
        <Modal
          size="lg"
          isOpen={this.state.modal_large}
          toggle={this.tog_large}
        >
          <ModalHeader toggle={() => this.setState({ modal_large: false })}>
            Add Institution / Organisation
          </ModalHeader>
          <ModalBody>
            <AvForm className="needs-validation">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="validationCustom02">
                      Institution / Organisation Name
                    </Label>
                    <AvField
                      name="institution"
                      placeholder="Institution / Organisation"
                      type="text"
                      errorMessage="Enter Institution / Organisation"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom02"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="validationCustom02">City</Label>
                    <AvField
                      name="city"
                      placeholder="Enter City of Institute / Organisation"
                      type="text"
                      errorMessage="Enter City"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom02"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="validationCustom02">State</Label>
                    <AvField
                      name="state"
                      placeholder="Enter State of Institute / Organisation"
                      type="text"
                      errorMessage="Enter State"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom02"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="validationCustom02">Country</Label>
                    <AvField
                      name="country"
                      placeholder="Enter Country of Institute / Organisation"
                      type="text"
                      errorMessage="Enter Country"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom02"
                    onValidSubmit={this.submitNewCategory}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="info" type="submit" >
                Add
              </Button>
            </AvForm>
          </ModalBody>
        </Modal>
        {/* Modal ENDS for Add Organisation */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryState: state.CategoryReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategoriesrdx: () => dispatch(initFetchCategories()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateService);
