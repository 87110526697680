import {
  REGISTER_REQUEST_INIT,
  REGISTER_REQUEST_SUCCESS,
  REGISTER_REQUEST_FAILURE,
} from "./actionTypes";

const registerInit = (user) => {
  return {
    type: REGISTER_REQUEST_INIT,
    payload: user,
  };
};

const registerSuccess = () => {
  return {
    type: REGISTER_REQUEST_SUCCESS,
  };
};

const registerFailure = (error) => {
  return {
    type: REGISTER_REQUEST_FAILURE,
    payload: error,
  };
};

export { registerInit, registerSuccess, registerFailure };
