import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";

const WpOptionGroup = [
  {
    options: [
      { label: "Sales", value: "Sales" },
      { label: "Business Development", value: "Business Development" },
      { label: "Marketing", value: "Marketing" },
      { label: "Operations", value: "Operations" },
      { label: "Technology", value: "Technology" },
      { label: "Finance", value: "Finance" },
      { label: "Digital Marketing", value: "Digital Marketing" },
      { label: "HR", value: "HR" },
      { label: "Talent Acqusition", value: "Talent Acquisition" },
      { label: "Employee Care", value: "Employee Care" },
      { label: "Business HR", value: "Business HR" },
      { label: "Business Analytics", value: "Business Analytics" },
      { label: "Data Quality", value: "Data Quality" },
      { label: "Supply Chain Management", value: "Supply Chain Management" },
      { label: "Investment Banking", value: "Investment Banking" },
      { label: "Credit", value: "Credit" },
      { label: "Operations", value: "Operations" },
      { label: "Collections", value: "Collections" },
      { label: "NPA Management", value: "NPA Management" },
      { label: "Risk & Control", value: "Risk & Control" },
      { label: "Banking", value: "Banking" },
    ],
  },
];

class WpOtherDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "Form Advanced", link: "#" },
      ],
      selectedGroup: null,
      selectedMulti: null,
    };
  }
  handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };
  render() {
    const { selectedMulti } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col md={12}>
            <h4 className="education-details-heading">Education Details</h4>
            <AvForm className="needs-validation">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="ugdegree">UG Degree</Label>
                    <AvField
                      name="ugdegree"
                      placeholder="Ex: B.Tech"
                      type="text"
                      errorMessage="Enter UG Degree"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="ugdegree"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="pgdegree">PG Degree</Label>
                    <AvField
                      name="pgdegree"
                      placeholder="CGPA/Percentage"
                      type="text"
                      className="form-control"
                      id="pgdegree"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="currComp">Current Company</Label>
                    <AvField
                      name="currComp"
                      placeholder="Ex: Amazon"
                      type="text"
                      errorMessage="Enter Current Company"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="currComp"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="exp">Total Working Experience</Label>
                    <AvField
                      name="exp"
                      placeholder="Ex: 5 Years"
                      type="number"
                      errorMessage="Enter Total Working experience"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="exp"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Interested Areas</Label>
                    <Select
                      menuPlacement="top"
                      value={selectedMulti}
                      isMulti={true}
                      onChange={this.handleMulti}
                      options={WpOptionGroup}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Button color="success" type="submit">
                Submit Details
              </Button>
            </AvForm>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default WpOtherDetails;
