import React, { Component } from "react";
import { api_route } from "../../Api-config/config";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import { message, notification } from "antd";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";

// import images
import egslogo from "../../assets/images/egs-logo.png";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./login.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      otp: "",
      otpGenerated: false,
      loginError: "",
      showLoader: false,
      category: "student",
      showResendBtn: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, values) {
    this.checkLogin(values);
  }

  handleCategory = (e) => {
    e.persist();

    let { value } = e.target;
    this.setState({ category: value });
  };

  componentDidMount() {
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

   
  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  getLoggedIn = () => {
    fetch(api_route + "/super_admin/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.state.email,
        otp: this.state.otp,
      }),
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          res.json().then((data) => {
            if (data.message === "login successful") {
              localStorage.setItem("token", `${data.data.access_token}`);
              localStorage.setItem("category", `${data.data.role}`);
              // localStorage.setItem("username", `${data.userName}`);
              localStorage.setItem("email", `${this.state.email}`);
              this.setState({
                loginError: false,
              });
              this.props.history.push("/dashboard");
              // data.isDealer
              //   ? this.props.history.push("/dealerDashboard")
              //   : this.props.history.push("/dashboard");
            } else {
              console.log(data.msg);
              this.setState({
                loginError: data.message,
              });
            }
          });
        } else if (res.status === 500) {
          this.setState({
            loginError: "Internal server error",
          });
        } else {
          res.json().then((data) => {
            console.log(data.msg);
            this.setState({
              loginError: data.message,
            });
            //   notification.error({ message: msg });
          });
        }
      })
      .catch((err) => {
        this.setState({
          loginError: err,
        });
        // notification.error({ message: "Error occurred" });
      });
  };

  requestOtpGeneration = () => {
    setTimeout(() => {
      this.setState({
        showResendBtn: true,
      });
    }, 3000);
    this.setState({
      showLoader: true,
    });
    fetch(api_route + `/super_admin/login_request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.state.email,
      }),
    })
      .then((res) => {
        this.setState({
          showLoader: false,
        });
        if (res.status === 200 || res.status === 201) {
          res.json().then((data) => {
            if (data.message === "successful otp sent") {
              this.setState({
                otpGenerated: true,
                loginError: false,
              });
            } else {
              console.log(data.message);
              this.setState({
                loginError: data.message,
              });
            }
          });
        } else if (res.status === 500) {
          this.setState({
            loginError: "Internal server error",
          });
        } else {
          res.json().then((data) => {
            console.log(data.message);
            this.setState({
              loginError: data.message,
            });
            //   notification.error({ message: msg });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loginError: err,
          showLoader: false,
        });
        // notification.error({ message: "Error occurred" });
      });
  };

  generateOtp = () => {
    // if (this.state.otpGenerated) {
    if (1) {
      this.getLoggedIn();
    } else {
      this.requestOtpGeneration();
    }
  };

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0 login-screen">
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={4}>
                        <div className="login-card">
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                {/* <img src={egslogo} width="130" alt="logo" /> */}
                              </Link>
                            </div>

                            <h4 className="font-size-22 mt-4">
                              Welcome to{" "}
                              <span style={{ color: "#00a0e3" }}>EGS</span>
                              {/* <span style={{ color: "#f29440" }}>Labs</span> */}
                            </h4>
                          </div>

                          {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null}

                          <div className="text-center p-2 mt-2 mb-2">
                            {this.state.loginError && this.state.loginError ? (
                              <Alert color="danger">
                                {this.state.loginError}
                              </Alert>
                            ) : null}

                            {this.state.otpGenerated &&
                            !this.state.loginError &&
                            this.state.otpGenerated ? (
                              <Alert color="success">
                                OTP has been sent to your registered email!
                              </Alert>
                            ) : null}

                            {this.state.showLoader ? (
                              <Spin indicator={antIcon} />
                            ) : null}
                          </div>

                          <div className="p-2">
                            <AvForm
                              className="form-horizontal"
                              // onValidSubmit={this.handleSubmit}
                            >
                              {/* <AvRadioGroup
                                name="radioExample"
                                label="Login as"
                                required
                                inline
                                errorMessage="Pick one!"
                                onChange={
                                  (e) => this.handleCategory(e)
                                  // console.log(e.target.value)
                                }
                              >
                                <AvRadio
                                  checked
                                  label="Student"
                                  value="student"
                                />
                                <AvRadio
                                  label="Working Professional"
                                  value="workingProfessional"
                                />
                                <AvRadio
                                  label="Business Enterprise"
                                  value="businessEnterprise"
                                />
                              </AvRadioGroup> */}
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="Email">Email</Label>
                                <AvField
                                  name="username"
                                  // value={this.state.username}
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  validate={{ email: true, required: true }}
                                  placeholder="Enter Email Id"
                                  onChange={(e) =>
                                    this.setState({ email: e.target.value })
                                  }
                                />
                              </FormGroup>
                              {1 && (
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="Otp">OTP</Label>
                                  <AvField
                                    name="otp"
                                    // value={this.state.username}
                                    type="password"
                                    className="form-control"
                                    id="otp"
                                    validate={{ required: true }}
                                    placeholder="Enter otp"
                                    onChange={(e) =>
                                      this.setState({ otp: e.target.value })
                                    }
                                  />
                                </FormGroup>
                              )}

                              <div className="mt-4 text-center">
                                <Button
                                  className="skill-btn w-md waves-effect waves-light"
                                  type="submit"
                                  onClick={this.generateOtp}
                                >
                                  {this.state.otpGenerated
                                    ? "Login"
                                    : "Submit"}
                                </Button>
                              </div>
                              {this.state.showResendBtn && (
                                <div className="text-center mt-3">
                                  <a onClick={this.requestOtpGeneration}>
                                    Resend OTP
                                  </a>
                                </div>
                              )}

                              {/* <div className="mt-4 text-center">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock mr-1"></i> Forgot
                                  your password?
                                </Link>
                              </div> */}
                            </AvForm>
                          </div>

                          <div
                            className="text-center"
                            style={{ paddingTop: "10px" }}
                          >
                            <p>
                              {/* Don't have an account ?{" "} */}
                              {" "}
                              <Link
                                to="/register"
                                className="font-weight-medium register-link-text"
                                aria-disabled='true'
                              >
                                {" "}
                                {/* Register{" "} */}
                                {" "}
                              </Link>{" "}
                            </p>
                            <p>© 2022 EGS.</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/* <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
