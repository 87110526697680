import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

class AssignedServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_large: false,
      breadcrumbItems: [
        { title: "EGS", link: "#" },
        { title: "Assigned Services", link: "#" },
      ],
    };
  }
  render() {
    const data = {
      columns: [
        {
          label: "Name",
          field: "name",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Service",
          field: "service",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Category",
          field: "category",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Organisation",
          field: "organisation",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Revenue",
          field: "revenue",
          sort: "disabled",
          width: 150,
        },
      ],
      rows: [
        {
          name: "Amit Kumar",
          service: "Admissions",
          category: "Student",
          organisation: "BPIT",
          revenue: "Rs. 20,000",
        },
        {
          name: "Amit Kumar",
          service: "Admissions",
          category: "Mentor",
          organisation: "BPIT",
          revenue: "Rs. 20,000",
        },
        {
          name: "Amit Kumar",
          service: "Admissions",
          category: "MSME",
          organisation: "BPIT",
          revenue: "Rs. 20,000",
        },
      ],
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Assigned Services"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <MDBDataTable responsive bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AssignedServices;
