import {
  INIT_FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
} from "./actionTypes";

const initialState = {
  loading: false,
  message: "",
  error: "",
  values: [],
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_FETCH_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        loading: false,
        message: "Categories fetch successfully",
        error: "",
        values: action.payload,
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        loading: false,
        message: "",
        error: action.payload,
        values: [],
      };
    default:
      return state;
  }
};

export default CategoryReducer;
