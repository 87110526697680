import { api_route } from "../../Api-config/config";
import { notification } from "antd";

const getServicesAPI = async (url, data) => {
  try {
    const response = await fetch(api_route + url, {
      method: "GET",
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    });
    if (response.status >= 200 && response.status <= 299) {
      const res = await response.json();
      return res;
    }
    throw response;
  } catch (err) {
    var message;
    if (err && err.status) {
      switch (err.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          const res = await err.json();
          message = res.error;
          break;
      }
    }

    notification.error({ message: "Error", description: message });

    throw message;
  }
};

export { getServicesAPI };
