import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";

const optionGroup = [
  {
    options: [
      { label: "Business", value: "Business" },
      { label: "Commerce", value: "Commerce" },
      { label: "Technology", value: "Technology" },
      { label: "Finance", value: "Finance" },
      { label: "Marketing", value: "Marketing" },
      { label: "Digital Marketing", value: "Digital Marketing" },
      { label: "HR", value: "HR" },
      { label: "Recruitement", value: "Recruitement" },
      { label: "Business Analytics", value: "Business Analytics" },
      { label: "Data Science", value: "Data Science" },
      { label: "Operations", value: "Operations" },
      { label: "Supply Chain Management", value: "Supply Chain Management" },
      { label: "Investment Banking", value: "Investment Banking" },
      { label: "Computer Science", value: "Computer Science" },
      { label: "Mechanical", value: "Mechanical" },
      { label: "Electrical", value: "Electrical" },
      { label: "Engineering", value: "Engineering" },
      { label: "Civil", value: "Civil" },
      { label: "Science", value: "Science" },
      { label: "Humanities", value: "Humanities" },
      { label: "Sales", value: "Sales" },
      { label: "Entrepreneurship", value: "Entrepreneurship" },
    ],
  },
];

class UserEducationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "Form Advanced", link: "#" },
      ],
      selectedGroup: null,
      selectedMulti: null,
    };
  }
  handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };
  render() {
    const { selectedMulti } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col md={12}>
            <h4 className="education-details-heading">Education Details</h4>
            <AvForm className="needs-validation">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="tenthboard">10th Board Name</Label>
                    <AvField
                      name="tenthboard"
                      placeholder="Ex: CBSE"
                      type="text"
                      errorMessage="Enter 10th Board Name"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="tenthboard"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="tenthmarks">10th Marks</Label>
                    <AvField
                      name="tenthmarks"
                      placeholder="CGPA/Percentage"
                      type="number"
                      errorMessage="Enter 10th Marks"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="tenthmarks"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="twelthboard">12th/Diploma Board Name</Label>
                    <AvField
                      name="twelthboard"
                      placeholder="Ex: CBSE"
                      type="text"
                      errorMessage="Enter 12th/Diploma Board Name"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="twelthboard"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="twelthmarks">12th/Diploma Marks</Label>
                    <AvField
                      name="twelthmarks"
                      placeholder="CGPA/Percentage"
                      type="number"
                      errorMessage="Enter 12th/Diploma Marks"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="twelthmarks"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="uginstitute">UG Institution Name</Label>
                    <AvField
                      name="uginstitute"
                      placeholder="BPIT"
                      type="text"
                      className="form-control"
                      id="uginstitute"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="ugdegree">UG Degree</Label>
                    <AvField
                      name="ugdegree"
                      placeholder="Ex: B.Tech"
                      type="text"
                      className="form-control"
                      id="ugdegree"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="ugmarks">UG Marks</Label>
                    <AvField
                      name="ugmarks"
                      placeholder="CGPA/Percentage"
                      type="number"
                      className="form-control"
                      id="ugmarks"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="pginstitute">PG Institution Name</Label>
                    <AvField
                      name="pginstitute"
                      placeholder="BPIT"
                      type="text"
                      className="form-control"
                      id="pginstitute"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="pgdegree">PG Degree</Label>
                    <AvField
                      name="pgdegree"
                      placeholder="Ex: MBA"
                      type="text"
                      className="form-control"
                      id="pgdegree"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="pgmarks">PG Marks</Label>
                    <AvField
                      name="pgmarks"
                      placeholder="CGPA/Percentage"
                      type="number"
                      className="form-control"
                      id="pgmarks"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Interested Areas</Label>
                    <Select
                      menuPlacement="top"
                      value={selectedMulti}
                      isMulti={true}
                      onChange={this.handleMulti}
                      options={optionGroup}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Button color="success" type="submit">
                Submit Details
              </Button>
            </AvForm>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default UserEducationDetails;
