import React, { Component } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Container } from "reactstrap";

import UserMainDetails from "./UserMainDetails";
import UserEducationDetails from "./UserEducationDetails";

import WpMainDetails from "./WpMainDetails";
import WpOtherDetails from "./WpOtherDetails";
import "./user.css";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "EGS", link: "#" },
        { title: "Profile", link: "#" },
      ],
    };
  }
  render() {
    const userdetails = [
      {
        profileImg: require("../../assets/images/users/avatar-2.jpg"),
        userName: "Amrit Maan",
        userEmail: "amrit@gmail.com",
        userPhn: 9856986598,
      },
    ];

    const wpDetails = [
      {
        profileImg: require("../../assets/images/users/avatar-3.jpg"),
        userName: "Ansh Kumar",
        userEmail: "ansh@gmail.com",
        userPhn: 9856986598,
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Profile"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            {/* Student Profile Starts */}
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div className="profile-sec">
                      <Row>
                        <Col md={4}>
                          <WpMainDetails userdetails={userdetails[0]} />
                        </Col>
                        <Col md={8}>
                          <UserEducationDetails />
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Student Profile ENDS */}

            {/* Working Professional Profile Starts */}
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div className="profile-sec">
                      <Row>
                        <Col md={4}>
                          <UserMainDetails userdetails={wpDetails[0]} />
                        </Col>
                        <Col md={8}>
                          <WpOtherDetails />
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Working Professional Profile ENDS */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default UserProfile;
