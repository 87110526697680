import {
  UPLOADCSV_REQUEST_INIT,
  UPLOADCSV_REQUEST_SUCCESS,
  UPLOADCSV_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  message: "",
  error: "",
  loading: false,
};

const UploadCsvReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOADCSV_REQUEST_INIT:
      return {
        ...state,
        loading: true,
      };
    case UPLOADCSV_REQUEST_SUCCESS:
      return {
        message: "Successful register",
        loading: false,
        error: "",
      };
    case UPLOADCSV_REQUEST_FAILURE:
      return {
        message: "",
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default UploadCsvReducer;
