import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EnrolledStudentsTable from "./EnrolledStudentsTable";
import ViewDocs from "./ViewDocs";
import ViewInternshipDetails from "./ViewInternshipDetails";
import ViewProfile from "./ViewProfile";

class EnrolledStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_large: false,
      modal_internship: false,
      modal_profile: false,
      breadcrumbItems: [
        { title: "EGS", link: "#" },
        { title: "Enrolled Students", link: "#" },
      ],
    };
    this.tog_large = this.tog_large.bind(this);
    this.tog_internship = this.tog_internship.bind(this);
    this.tog_profile = this.tog_profile.bind(this);
  }

  tog_internship() {
    this.setState((prevState) => ({
      modal_internship: !prevState.modal_internship,
    }));
    this.removeBodyCss();
  }

  tog_large() {
    this.setState((prevState) => ({
      modal_large: !prevState.modal_large,
    }));
    this.removeBodyCss();
  }

  tog_profile() {
    this.setState((prevState) => ({
      modal_profile: !prevState.modal_profile,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }
  render() {
    const studentsData = [
      {
        id: 1,
        studentName: "Amrit Singh",
        studentEmail: "Amrit@gmail.com",
        studentPhn: 9865986598,
        studentImg: require("../../assets/images/users/avatar-2.jpg"),
        tenthBoardName: "CBSE",
        tenthMarks: 9.0,
        twelthBoardName: "CBSE",
        twelthMarks: 85,
        UGInstituteName: "BPIT",
        UGDegree: "B.Tech",
        UGMarks: 73,
        PGInstituteName: "BPIT",
        PGDegree: "MBA",
        PGMarks: 82,
        InterestedAreas: "Business, Technology, Marketing, Data Science, Supply Chain Management",
        viewInternshipDetails: (
          <button onClick={this.tog_internship} className="btn btn-success">
            View
          </button>
        ),
        viewDocuments: (
          <button onClick={this.tog_large} className="btn btn-success">
            View
          </button>
        ),
        viewProfile: <button onClick={this.tog_profile} className="btn btn-success">View</button>,
      },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Enrolled Students"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <EnrolledStudentsTable data={studentsData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Internship Details Modal Starts */}
            <Modal
              size="lg"
              isOpen={this.state.modal_internship}
              toggle={this.tog_internship}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_internship: false })}
              >
                Internship Details
              </ModalHeader>
              <ModalBody>
                <ViewInternshipDetails />
              </ModalBody>
            </Modal>
            {/* Internship Detail Modal Ends */}

            {/* Documents Modal */}
            <Modal
              size="lg"
              isOpen={this.state.modal_large}
              toggle={this.tog_large}
            >
              <ModalHeader toggle={() => this.setState({ modal_large: false })}>
                View Documents
              </ModalHeader>
              <ModalBody>
                <ViewDocs />
              </ModalBody>
            </Modal>
            {/* Document Modal Ends */}

            {/* Profile Modal Starts */}
            <Modal
              isOpen={this.state.modal_profile}
              toggle={this.tog_profile}
              scrollable={true}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_profile: false })}
              >
                Student Profile
              </ModalHeader>
              <ModalBody>
                <ViewProfile data={studentsData[0]}/>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EnrolledStudents;
