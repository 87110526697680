import { fork, all, takeEvery, put, call } from "redux-saga/effects";
import {
  INIT_FETCH_ADMINS,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
} from "./actionTypes";

import { fetchAdminsApi } from "../../helpers/ApiManager/admins";
import { fetchAdminSuccess, fetchAdminFailure } from "./action";

function* fetchAdmins() {
  try {
    const response = yield call(fetchAdminsApi, "/super_admin/get_admins");
    yield put(fetchAdminSuccess(response.data));
  } catch (err) {
    yield put(fetchAdminFailure(err));
  }
}

function* watchFetchAdmin() {
  yield takeEvery(INIT_FETCH_ADMINS, fetchAdmins);
}

function* AdminSaga() {
  yield all([fork(watchFetchAdmin)]);
}

export default AdminSaga;
