import React from "react";
import { Row, Col, Container } from "reactstrap";
import "./user.css";

const UserMainDetails = (props) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <div className="user-main-sec">
            <div className="user-img">
              <img src={props.userdetails.profileImg}></img>
            </div>
            <div className="user-name">
              <h4>{props.userdetails.userName}</h4>
            </div>
            <div className="user-email">
              <h4>{props.userdetails.userEmail}</h4>
            </div>
            <div className="user-phn">
              <h4>{props.userdetails.userPhn}</h4>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserMainDetails;
