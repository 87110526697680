import {
  INIT_FETCH_ADMINS,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
} from "./actionTypes";

const initialState = {
  loading: false,
  message: "",
  error: "",
  admins: [],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_FETCH_ADMINS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ADMINS_SUCCESS:
      return {
        loading: false,
        message: "fetched admins successfully",
        error: "",
        admins: action.payload,
      };
    case FETCH_ADMINS_FAILURE:
      return {
        loading: false,
        message: "",
        error: action.payload,
        admins: [],
      };

    default:
      return state;
  }
};

export default adminReducer;
