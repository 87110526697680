import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";

import "./pendingreq.css";

class PendingRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_large: false,
      breadcrumbItems: [
        { title: "EGS", link: "#" },
        { title: "Pending Requests", link: "#" },
      ],
    };
  }
  render() {
    const studentData = {
      columns: [
        {
          label: "Student Name",
          field: "stuName",
          sort: "disabled",
          width: 190,
        },
        {
          label: "View Details",
          field: "viewDetails",
          sort: "disabled",
          width: 90,
        },
        {
          label: "Accept",
          field: "accept",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Reject",
          field: "reject",
          sort: "disabled",
          width: 130,
        },
      ],
      rows: [
        {
          stuName: "Amrit Kumar",
          viewDetails: (
            <button onClick={this.tog_large} className="btn btn-info">
              View Details
            </button>
          ),
          accept: <button className="btn btn-success">Accept</button>,
          reject: <button className="btn btn-danger">Reject</button>,
        },
        {
          stuName: "Rajveer Singh",
          viewDetails: (
            <button onClick={this.tog_large} className="btn btn-info">
              View Details
            </button>
          ),
          accept: <button className="btn btn-success">Accept</button>,
          reject: <button className="btn btn-danger">Reject</button>,
        },
        {
          stuName: "Sonia Singh",
          viewDetails: (
            <button onClick={this.tog_large} className="btn btn-info">
              View Details
            </button>
          ),
          accept: <button className="btn btn-success">Accept</button>,
          reject: <button className="btn btn-danger">Reject</button>,
        },
        {
          stuName: "Kamal Kumar",
          viewDetails: (
            <button onClick={this.tog_large} className="btn btn-info">
              View Details
            </button>
          ),
          accept: <button className="btn btn-success">Accept</button>,
          reject: <button className="btn btn-danger">Reject</button>,
        },
      ],
    };

    const mentorData = {
        columns: [
          {
            label: "Mentor Name",
            field: "stuName",
            sort: "disabled",
            width: 190,
          },
          {
            label: "View Details",
            field: "viewDetails",
            sort: "disabled",
            width: 90,
          },
          {
            label: "Accept",
            field: "accept",
            sort: "disabled",
            width: 100,
          },
          {
            label: "Reject",
            field: "reject",
            sort: "disabled",
            width: 130,
          },
        ],
        rows: [
          {
            stuName: "Amrit Kumar",
            viewDetails: (
              <button onClick={this.tog_large} className="btn btn-info">
                View Details
              </button>
            ),
            accept: <button className="btn btn-success">Accept</button>,
            reject: <button className="btn btn-danger">Reject</button>,
          },
          {
            stuName: "Rajveer Singh",
            viewDetails: (
              <button onClick={this.tog_large} className="btn btn-info">
                View Details
              </button>
            ),
            accept: <button className="btn btn-success">Accept</button>,
            reject: <button className="btn btn-danger">Reject</button>,
          },
          {
            stuName: "Sonia Singh",
            viewDetails: (
              <button onClick={this.tog_large} className="btn btn-info">
                View Details
              </button>
            ),
            accept: <button className="btn btn-success">Accept</button>,
            reject: <button className="btn btn-danger">Reject</button>,
          },
          {
            stuName: "Kamal Kumar",
            viewDetails: (
              <button onClick={this.tog_large} className="btn btn-info">
                View Details
              </button>
            ),
            accept: <button className="btn btn-success">Accept</button>,
            reject: <button className="btn btn-danger">Reject</button>,
          },
        ],
      };

      const msmeData = {
        columns: [
          {
            label: "MSME Name",
            field: "stuName",
            sort: "disabled",
            width: 190,
          },
          {
            label: "View Details",
            field: "viewDetails",
            sort: "disabled",
            width: 90,
          },
          {
            label: "Accept",
            field: "accept",
            sort: "disabled",
            width: 100,
          },
          {
            label: "Reject",
            field: "reject",
            sort: "disabled",
            width: 130,
          },
        ],
        rows: [
          {
            stuName: "Amrit Kumar",
            viewDetails: (
              <button onClick={this.tog_large} className="btn btn-info">
                View Details
              </button>
            ),
            accept: <button className="btn btn-success">Accept</button>,
            reject: <button className="btn btn-danger">Reject</button>,
          },
          {
            stuName: "Rajveer Singh",
            viewDetails: (
              <button onClick={this.tog_large} className="btn btn-info">
                View Details
              </button>
            ),
            accept: <button className="btn btn-success">Accept</button>,
            reject: <button className="btn btn-danger">Reject</button>,
          },
          {
            stuName: "Sonia Singh",
            viewDetails: (
              <button onClick={this.tog_large} className="btn btn-info">
                View Details
              </button>
            ),
            accept: <button className="btn btn-success">Accept</button>,
            reject: <button className="btn btn-danger">Reject</button>,
          },
          {
            stuName: "Kamal Kumar",
            viewDetails: (
              <button onClick={this.tog_large} className="btn btn-info">
                View Details
              </button>
            ),
            accept: <button className="btn btn-success">Accept</button>,
            reject: <button className="btn btn-danger">Reject</button>,
          },
        ],
      };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Pending Requests"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <div className="students-heading">
              <h4>Requests From Students</h4>
            </div>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      scrollY
                      maxHeight="260px"
                      paging={false}
                      bordered
                      data={studentData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="students-heading">
              <h4>Requests From Mentors</h4>
            </div>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      scrollY
                      maxHeight="260px"
                      paging={false}
                      bordered
                      data={mentorData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="students-heading">
              <h4>Requests From MSME's</h4>
            </div>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      scrollY
                      maxHeight="260px"
                      paging={false}
                      bordered
                      data={msmeData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default PendingRequest;
