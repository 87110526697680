import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

class ViewServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_large: false,
    };
    this.tog_large = this.tog_large.bind(this);
  }

  tog_large() {
    this.setState((prevState) => ({
      modal_large: !prevState.modal_large,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  render() {
    const data = {
      columns: [
        {
          label: "Service Name",
          field: "serviceName",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Institution/Organization Name",
          field: "instituteName",
          sort: "disabled",
          width: 200,
        },
        {
          label: "View Details",
          field: "viewDetails",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Delete Service",
          field: "delete",
          sort: "disabled",
          width: 150,
        },
      ],
      rows: [
        {
          serviceName: "Admissions",
          instituteName: "BPIT",
          viewDetails: <button onClick={this.tog_large} className="btn btn-primary">View</button>,
          delete: <button className="btn btn-danger">Delete</button>,
        },
        {
          serviceName: "Courses",
          instituteName: "BPIT",
          viewDetails: <button className="btn btn-primary">View</button>,
          delete: <button className="btn btn-danger">Delete</button>,
        },
        {
          serviceName: "Overseas",
          instituteName: "MAIT",
          viewDetails: <button className="btn btn-primary">View</button>,
          delete: <button className="btn btn-danger">Delete</button>,
        },
        {
          serviceName: "Admissions",
          instituteName: "IIT",
          viewDetails: <button className="btn btn-primary">View</button>,
          delete: <button className="btn btn-danger">Delete</button>,
        },
      ],
    };
    return (
      <React.Fragment>
        <MDBDataTable responsive bordered data={data} />

        {/* Modal for View Details of Service */}
        <Modal
          size="lg"
          isOpen={this.state.modal_large}
          toggle={this.tog_large}
        >
          <ModalHeader toggle={() => this.setState({ modal_large: false })}>
            Admission
          </ModalHeader>
          <ModalBody>
            <h5>Institution / Organization Name</h5>
            <p>BPIT</p>

            <h5>Commision</h5>
            <p>Rs 10,000</p>
          </ModalBody>
        </Modal>
        {/* Modal ENDS for View Details of Service */}

      </React.Fragment>
    );
  }
}

export default ViewServices;
