if (process.env.NODE_ENV === "development") {
  module.exports = {
    // api_route: "http://127.0.0.1:5000/api",
    api_route: "https://backend.evreneducation.com/api",
    

    awsconfig: {
      bucketName: "EGS",
      region: "us-east-1",
      accessKeyId: "AKIAZODV4YIG6YB6D2EK",
      secretAccessKey: "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",
    },
  };
} else {
  module.exports = {
    // api_route: "http://127.0.0.1:5000/api",
    api_route: "https://backend.evreneducation.com/api",

    awsconfig: {
      bucketName: "EGS",
      region: "us-east-1",
      accessKeyId: "AKIAZODV4YIG6YB6D2EK",
      secretAccessKey: "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",
    },
  };
}
