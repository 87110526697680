import {
  INIT_FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
} from "./actionTypes";

const initFetchCategories = () => {
  return {
    type: INIT_FETCH_CATEGORIES,
  };
};

const fetchCategoriesSuccess = (values) => {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: values,
  };
};

const fetchCategoriesFailure = (error) => {
  return {
    type: FETCH_CATEGORIES_FAILURE,
    payload: error,
  };
};

export { initFetchCategories, fetchCategoriesSuccess, fetchCategoriesFailure };
