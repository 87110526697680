import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import "./dashboard.scss";

const TotalRevenue = (props) => {
  return (
    <>
      <Col md={4}>
        <Card>
          <CardBody>
            <Row>
              <Col md={6}>
                <h4 className="card-title">Total Revenue</h4>
              </Col>
              <Col md={6}>
                <div style={{textAlign: "right"}}>
                  <h5>Rs. {props.totalRevenue.revenue}</h5>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default TotalRevenue;
