import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Alert,
  Input,
  Container,
  Spinner,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
  CardHeader,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";
import { notification, message } from "antd";

import { withRouter } from "react-router-dom";
import { uploadInit } from "../../store/actions";

class SubadminRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "Form Advanced", link: "#" },
      ],
      selectedGroup: null,
      selectedMulti: null,
      selectedFile: null,
      category: "",
    };
  }

  handleCategory = (e) => {
    this.setState({
      category: e.target.value,
    });
  };

  handleCsvFile = (e) => {
    this.setState({
      selectedFile: e.target.files[0],
    });
  };

  handleSubmit = (event, values) => {
    values.csvFile = this.state.selectedFile;
    // console.log(values);
    this.props.uploadInit(values);
    this.resetForm();
  };

  resetForm = () => {
    this.form.reset();
  };

  render() {
    const { selectedMulti } = this.state;

    return (
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardHeader>
              <h4 className="">Bulk Uploads</h4>
            </CardHeader>
            <CardBody>
              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
              >
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="tenthboard">Category</Label>
                      <AvField
                        name="category"
                        placeholder="Category"
                        type="select"
                        errorMessage="Enter Category"
                        className="form-control"
                        onChange={this.handleCategory}
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="category"
                      >
                        <option>Select Category</option>
                        <option value="0">Student</option>
                        <option value="1">Working Professional</option>
                        <option value="2">MSME</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="tenthboard">Sampe Csv</Label>
                      <Link
                        to={
                          this.state.category == 0
                            ? "files/student.csv"
                            : this.state.category == 1
                            ? "files/wp.csv"
                            : "files/msme.csv"
                        }
                        target="_blank"
                        download
                      >
                        <img src="https://img.icons8.com/color/30/000000/xls.png" />
                        {/* <Button color="success">Download</Button> */}
                      </Link>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="employment_type">Upload Csv</Label>
                      <AvField
                        name="csv"
                        onChange={this.handleCsvFile}
                        placeholder="CSV"
                        type="file"
                        accept=".csv"
                        errorMessage="Select File"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="csv"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Button color="success" type="submit">
                  {this.props.loading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    error: state.UploadCsvReducer.error,
    message: state.UploadCsvReducer.message,
    loading: state.UploadCsvReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadInit: (payload) => dispatch(uploadInit(payload)),
  };
};

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(SubadminRegister)
);
