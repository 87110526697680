import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Alert,
  Input,
  Container,
  Spinner,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
  CardHeader,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";
import { notification, message } from "antd";

import { withRouter } from "react-router-dom";
import { registerInit } from "../../store/actions";

class SubadminRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "Form Advanced", link: "#" },
      ],
      selectedGroup: null,
      selectedMulti: null,
    };
  }


  componentDidMount() {}

  handleSubmit = (event, values) => {
    this.props.register(values);
    this.resetForm();
  };

  

  // register=(values)=>{`
  //        fetch("http://127.0.0.1:80/api/super_admin/add_role")
  //        .then()
  //        .catch()
  // }


  
  register(values) {
    
    var formData = new FormData();
    formData.append('sub1', JSON.stringify(values));

    // Send data to the backend via POST
    fetch('http://127.0.0.1:80/api/super_admin/add_role', {

      method: 'POST', 
      mode: 'cors', 
      body: formData // body data type must match "Content-Type" header

    })
  }



  resetForm = () => {
    this.form.reset();
  };

  render() {
    const { selectedMulti } = this.state;

    return (
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardHeader>
              <h4 className="">Register SubAdmin</h4>
            </CardHeader>
            <CardBody>
              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
              >
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="tenthboard">Name</Label>
                      <AvField
                        name="name"
                        placeholder="Name"
                        type="text"
                        errorMessage="Enter Name"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="name"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="employment_type">Role</Label>
                      <AvField
                        name="role"
                        placeholder="Role"
                        type="select"
                        errorMessage="Enter Role"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="role"
                      >
                        <option>Select Role</option>
                        <option value={2}>Admin</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="candidate_profile"> Email</Label>
                      <AvField
                        name="email"
                        type="email"
                        errorMessage="Enter Email"
                        className="form-control"
                        validate={{
                          email: true,
                          required: {
                            value: true,
                          },
                        }}
                        id="email"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="job_description"> Contact</Label>
                      <AvField
                        name="contact"
                        type="number"
                        errorMessage="Enter Contact"
                        className="form-control"
                        validate={{
                          tel: true,
                          required: {
                            value: true,
                          },
                        }}
                        id="contact"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Button color="success" type="submit">
                  {this.props.loading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    error: state.RegisterReducer.error,
    message: state.RegisterReducer.message,
    loading: state.RegisterReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (user) => dispatch(registerInit(user)),
  };
};

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(SubadminRegister)
);
