import React, { useEffect, useState } from "react";
import { Table, Row, Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { api_route } from "../../../Api-config/config";
import { useHistory } from "react-router-dom";

const NewLeads = (props) => {
  const loaddata = props.getRejectedData;
  const history = useHistory();

  const [status, setstatus] = useState("");
  const [assigned_to, setassigned_to] = useState();
  const [rem1, setrem1] = useState();
  const [rem2, setrem2] = useState();
  // const [rem3, setrem3] = useState("");

  // useEffect(()=>{
  //   // saveApiCall();
  // },[loaddata])

  // const arrReverse= loaddata.data;
  //        const reversed = arrReverse.reverse();

  // const [newremark1, setremark1] = useState('wow');

  // const [addClick, setaddClick] = useState(true);

  // //console.log('====================================');
  // //console.log(loaddata);
  // //console.log('====================================');

  //  function remarkEdit(e){
  //   return (
  //   <input type="text" name="remark1" value={remark1} placeholder="remarks1" onChange={(e) => { setremark1(e.target.value) }} />
  //   );
  // }

  const categoryOption = [
    {
      options: [
        { label: "Student", value: "Student" },
        // { label: "Mentor", value: "Mentor" },
        // { label: "MSME", value: "MSME" },
      ],
    },
  ];

  const serviceOption = [
    {
      options: [
        { label: "Admission", value: "Admission" },
        { label: "Course", value: "Course" },
      ],
    },
  ];

  // const url = "http://127.0.0.1:80/api";

  // const [loaddata, setloaddata] = useState(
  // [
  // {
  //   id: 1,
  //   name: "Ashutosh",
  //   category: "Student",
  //   email: "Ashu@gmail.com",
  //   phn: 9898659568,
  //   service: "Admission",
  //   status: "Unassigned",
  //   assignedTo: (
  //     <select>
  //       <option>Select User</option>
  //       <option>abc</option>
  //       <option>xyz</option>
  //     </select>
  //   ),
  //   remark1: "Remark 1",
  //   remark2: "Remark 2",
  //   remark3: "Remark 3",
  // },
  // {
  //   id: 2,
  //   name: "Ashutosh",
  //   category: "Student",
  //   email: "Ashu@gmail.com",
  //   phn: 9898659568,
  //   service: "Admission",
  //   status: "Unassigned",
  //   assignedTo: (
  //     <select>
  //       <option>Select User</option>
  //       <option>abc</option>
  //       <option>xyz</option>
  //     </select>
  //   ),
  //   remark1: "Remark 1",
  //   remark2: "Remark 2",
  //   remark3: "Remark 3",
  // },
  // ]
  // );

  // useEffect(() => {
  //   fetch( url  + "/super_admin/get_leads",
  //   get
  //   )
  //   .then((res) => {
  //     if (!res.ok) { // error coming back from server
  //       throw Error('could not fetch the data for that resource');
  //     }

  //     //console.log(res.json());
  //      res.json();
  //   })
  //   .then( (data) => {
  //     // //console.log("seond data");
  //     //                        setIsPending(false);
  //     // var jsondata= JSO
  //     // //console.log(j);
  //     // const filterdata = data.filter(item => item.status === 0 ) ;

  //     setloaddata(data)
  //       //console.log(data);
  //       //                         setError(null);
  //   })
  //     .catch(err => {
  //       //                         // auto catches network / connection error
  //       //                         setIsPending(false);
  //       //                         setError(err.message);
  //       //console.log(err);
  //     })
  // },[]);

  // const [clicked, setC]

  // //console.log(`status value :->  ${status}`);
  // //console.log(assigned_to);

  const saveApiCall = (id) => {
    const data = {
      // updatedata
      lead_id: id,
      authority_id: assigned_to,
      status: status,
      remark1: rem1,
      remark2: rem2,
      remark3: "default",
      revenue: "0",
    };

    fetch(api_route + "/super_admin/assign_lead", {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "access-token": localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          // window.alert("Invalid REgisteration ");
          alert("Changes Saved");
          window.location.reload(true);
          history.push("/leadManagement/");
          // history.push("/dashboard");
        } else {
          alert("Some Error Occured");
        }
        return response.json();
      })
      .then(
        (json) => {
          // //console.log(json)
          // setUpdatedata();
          // this.setState({
          // 	user:json
          // });
        }
        // var history = useHistory();
      );
  };

  // let name, value;
  const handleInputs = (e) => {
    // //console.log("insdie");
    // //console.log(e);
    // //  to get the name field  of the input like (naem,email,phone,)
    // name = e.target.name;
    // // //  to get what user is filling in that field
    // value = e.target.value;
    // //console.log(e.target.value);
    // // after geting what user is fiillng now is time to store that field in the above
    // setrem1(e.target.value);
  };

  return (
    <>
      <div className="filtered-fields">
        <Row>
          <Col md={8}>
            <Row>
              <Col md={4}>
                <FormGroup className="select2-container">
                  <Label>Select Category</Label>
                  <Select
                    options={categoryOption}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className="select2-container">
                  <Label>Select Service</Label>
                  <Select
                    options={serviceOption}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Country</th>
            <th>E-Mail</th>
            <th>Phone No</th>
            <th>Service</th>
            <th>Status</th>
            <th>Assigned To</th>
            <th>Remark 1</th>
            <th>Remark 2</th>
            {/* <th>Remark 3</th> */}
          </tr>
        </thead>
        <tbody>
          {/* map::reverse_iterator iterator_name; */}
          {loaddata.data &&
            loaddata.data.slice(0).reverse().map(
              (loaditem, index) =>
                loaditem.status === 2 && (
                  <tr>
                    <td>{loaditem.id}</td>
                    <td>{loaditem.user_name}</td>
                    <td>{loaditem.country}</td>
                    <td>{loaditem.user_email}</td>
                    <td>{loaditem.user_phone_number}</td>
                    <td>{loaditem.service_name}</td>

                    <td>
                      <select
                        name="status"
                        onChange={(e) => setstatus(e.target.value)}
                      >
                        <option value="2">Rejected</option>
                        <option value="0">NewLead</option>
                        <option value="1">Pending</option>
                        <option value="3">Converted</option>
                      </select>
                    </td>

                    <td>
                      <select
                        name="assigned_to"
                        defaultValue={loaditem.assigned_to}
                        onChange={(e) => setassigned_to(e.target.value)}
                      >
                        <option value="0">None</option>
                        <option value="1">Dr. Piyush Bhardwaj</option>
                        <option value="2">Dr. Adarsh Bhardwaj</option>
                        <option value="3">Mr. Bikash Sinha</option>
                        <option value="4">Mr. Yash Sharma</option>
                        <option value="5">Ms. Monimala</option>
                        <option value="6">Ms. Shubha Sharma</option>
                        <option value="7">Mr. B.P. Arora</option>
                      </select>
                    </td>

                    {/* <td contentEditable="true" value={rem1} onChange={(e) => setrem1(e.target.value)}  > {loaditem.remark1}
                  </td> */}
                    <td>
                      <input
                        defaultValue={loaditem.remark1}
                        onChange={(e) => setrem1(e.target.value)}
                      />
                    </td>

                    {/* <td contentEditable="true" onChange={(e) => setrem2(e.target.value)} > */}
                    <td>
                      <input
                        defaultValue={loaditem.remark2}
                        onChange={(e) => setrem2(e.target.value)}
                      />
                    </td>

                    {/* <td   >
                  <input   Value={loaditem.remark3}  onChange={(e) => setrem3(e.target.value)} />
                  
                  </td> */}
                    <td>
                      <button onClick={() => saveApiCall(loaditem.id)}>
                        Save
                      </button>
                    </td>
                    {/* <td>{loaditem.remark2}</td>
                  <td>{loaditem.remark3}</td> */}
                  </tr>
                )
            )}

          {/* <td>{loaditem.remarkEdit}</td> */}
        </tbody>
      </Table>
    </>
  );
};

export default NewLeads;
