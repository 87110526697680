import {
  INIT_FETCH_SERVICES,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
} from "./actionTypes";

const initFetchServices = () => {
  return {
    type: INIT_FETCH_SERVICES,
  };
};

const fetchServicesSuccess = (services) => {
  return {
    type: FETCH_SERVICES_SUCCESS,
    payload: services,
  };
};

const fetchServicesFailure = (err) => {
  return {
    type: FETCH_SERVICES_FAILURE,
    payload: err,
  };
};

export { fetchServicesFailure, initFetchServices, fetchServicesSuccess };
