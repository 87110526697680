import React from "react";
import { Table } from "reactstrap";

const StudentsUnderMsme = (props) => {
  return (
    <React.Fragment>
      <Table striped bordered responsive>
        <thead>
          <td>
            <b>S.No</b>
          </td>
          <td>
            <b>Student Name</b>
          </td>
          <td>
            <b>Email</b>
          </td>
          <td>
            <b>Phone</b>
          </td>
          <td>
            <b>Interested Areas</b>
          </td>
        </thead>
        <tbody>
          {props.data.map((data) => (
            <tr>
              <td>{data.id}</td>
              <td>{data.studentName}</td>
              <td>{data.studentEmail}</td>
              <td>{data.studentPhn}</td>
              <td>{data.interestedAreas}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default StudentsUnderMsme;
