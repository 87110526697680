import { all } from "redux-saga/effects";

//public
import accountSaga from "./auth/register/saga";
import loginSaga from "./auth/login/saga";
import forgetSaga from "./auth/forgetpwd/saga";
import LayoutSaga from "./layout/saga";
import SubAdminRegisterSaga from "./auth/subAdminRegister/saga";
import uploadcsvSaga from "./uploadCsv/saga";
import fetchCategoriesSaga from "./services/categories/saga";
import FetchServiceSaga from "./services/getServices/saga";
import AdminSaga from "./Admins/saga";

export default function* rootSaga() {
  yield all([
    //public
    accountSaga(),
    loginSaga(),
    forgetSaga(),
    LayoutSaga(),
    SubAdminRegisterSaga(),
    uploadcsvSaga(),
    fetchCategoriesSaga(),
    FetchServiceSaga(),
    AdminSaga(),
  ]);
}
