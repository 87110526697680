



import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Alert,
  Input,
  Container,
  Spinner,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
  CardHeader,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";
// import { notification, message } from "antd";

import { withRouter } from "react-router-dom";
import { registerInit } from "../../store/actions";
import { api_route } from "../../Api-config/config";

class IletesBatches extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loaddata:[],
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "Form Advanced", link: "#" },
      ],
      selectedGroup: null,
      selectedMulti: null,
    };
  }


  componentDidMount() {
    // this.register();
    this.showData();
  }

  handleSubmit = (event, values) => {
    this.register(values);
    this.resetForm();
    if(values!==null){

      window.alert("Saved Changes ")
    }
    window.location.reload(true);
    
  };

  

  // register=(values)=>{`
  //        fetch("http://127.0.0.1:80/api/super_admin/add_role")
  //        .then()
  //        .catch()
  // }


  
    register = async (values) => {
    


    var formData = new FormData();
    formData.append('sub1', JSON.stringify(values));
    // console.log(values);

    // Send data to the backend via POST
    var data = await fetch('https://backend.evreneducation.com/api/super_admin/create_class', {

      method: 'POST', 
      mode: 'cors', 
      headers: {
        "access-token": localStorage.getItem("token"),
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(values) // body data type must match "Content-Type" header

    }) 
      const data1 = await data.json();
    
//     let res = await fetch('http://127.0.0.1:5000/api/users/create_lead', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json;charset=utf-8',
//             'Access-Control-Allow-Origin': '*'
//         },
//         body: JSON.stringify(values)

  
//   })
}
    
deleteClass = async (id) => {
  console.log("delete functions call .....");
  const data = {
  "class_id":id,

  }
    
    // var formData = new FormData();
    // formData.append('sub1', JSON.stringify(values));
    // console.log(values);

    // Send data to the backend via POST
    // var data = await fetch('https://backend.evreneducation.com/api/super_admin/delete_class', {

    //   method: 'DELETE', 
    //   mode: 'cors', 
    //   headers: {
    //     "access-token": localStorage.getItem("token"),
    //     'Content-Type': 'application/json;charset=utf-8',
    //   },
    //   body: JSON.stringify(values) // body data type must match "Content-Type" header

    // }) 
   
     fetch(api_route + '/super_admin/delete_class', {
      method: 'DELETE',
      mode: 'cors', 
      headers: {
        "Content-type": "application/json",
        "access-token": localStorage.getItem("token"),
        "Access-Control-Allow-Origin": '*'
      },
      body: JSON.stringify(data)

    }).then(response => {
      if (response.status === 200) {
        // window.alert("Invalid REgisteration ");
        alert("Changes Saved")
        window.location.reload(true);
        // history.push("/leadManagement/");
        // history.push("/dashboard");
      }
      else {
        alert("Some Error Occured...");
      }
      return response.json()
    }).then(json => {
      // console.log(json)
      // setUpdatedata();
      // this.setState({
      // 	user:json
      // });
    }
      // var history = useHistory();


    );


}

 
  showData() {
    

    const get = {
        method: "GET",
        // headers: {
        //   "access-token": localStorage.getItem("token"),
        // }
      }
      fetch(api_route + "/users/get_all_classes",
        get
      )
        .then( async (res) =>  {
          if (!res.ok) { // error coming back from server
            throw Error('could not fetch the data for that resource');
          }
           
          else{
  
            var resjason=  await res.json();
            // console.log(resjason);
            this.setState({loaddata: resjason})
          }
        })
       
        .catch(err => {
          //                         // auto catches network / connection error
          //                         setIsPending(false);
          //                         setError(err.message);
          // console.log(err);
        })
  }



  resetForm = () => {
    this.form.reset();
  };

  render() {
    const { selectedMulti } = this.state;
    

    return (
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardHeader>
              <h4 className="">Register SubAdmin</h4>
            </CardHeader>
            <CardBody>
              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
              >
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="tenthboard">BatchName</Label>
                      <AvField
                        name="class_name"
                        
                        placeholder="Name"
                        type="text"
                        errorMessage="Enter BatchName"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        id="name"
                        
                      />
                    </FormGroup>
                  </Col>
                </Row>
               
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="candidate_profile"> Batch Start Date</Label>
                      <AvField
                        name="class_date"
                        type="date"
                        errorMessage="Enter Batch StartDate+"
                        className="form-control"
                        validate={{
                          date: true,
                          required: {
                            value: true,
                          },
                        }}
                        id="date"
                      />
                    </FormGroup>
                  </Col>
                </Row>
               

                <Button color="success" type="submit">
                  {this.props.loading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </AvForm>
            </CardBody>
          </Card>


          <Table striped bordered responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>BatchName</th>
            <th>Batch-Start-Date</th>
            
          </tr>
        </thead>
        <tbody>

          {
            (this.state.loaddata.data) ?
            this.state.loaddata.data.slice(0).reverse().map((loaditem, index) => (
                <tr>
                  <td>{index+1}</td>
                  <td>{loaditem.class_name}</td>
                  <td>{loaditem.class_date}</td>
                  <td><button className="btn btn-danger"  onClick={() => this.deleteClass(loaditem.id)} >Delete</button>
                  </td>
                 

                  
                  {/* <td>{loaditem.remark2}</td>
                  <td>{loaditem.remark3}</td> */}
                </tr>
              ))

              :

              console.log("")

          }

        </tbody>
      </Table>
        </Container>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    error: state.RegisterReducer.error,
    message: state.RegisterReducer.message,
    loading: state.RegisterReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (user) => dispatch(registerInit(user)),
  };
};

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(IletesBatches)
);


// import React from 'react'

// const Ilets = () => {
   

//     const [batchname, setBatchname] = useState("Enter BatchName");
//   const [batchStartDate, setbatchStartDate] = useState("");
  




//     return (
//         <>
//             <input
//                 type="text"
//                 name='batchname'
//                 id='batchname'
//                 autoComplete='off'
//                 value={batchname}

//             />
//         </>
//     )
// }

// export default Ilets;

