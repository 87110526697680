import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./createservice.css";

import CreateService from "./CreateService";
import ViewServices from "./ViewServices";

class CreateViewService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_large: false,
      breadcrumbItems: [
        { title: "EGS", link: "#" },
        { title: "Create / View Services", link: "#" },
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Create / View Services"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="create-service-heading">Create Service</h4>
                    <CreateService />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <h4 className="create-service-heading">View Services</h4>
                <Card>
                  <CardBody>
                    <ViewServices />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateViewService;
