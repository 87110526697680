import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { message } from "antd";
import { INIT_FETCH_CATEGORIES } from "./actionTypes";
import { fetchCategoriesFailure, fetchCategoriesSuccess } from "./action";
import { fetchCategoriesApi } from "../../../helpers/ApiManager/categories";

function* fetchCategories() {
  try {
    const response = yield call(
      fetchCategoriesApi,
      "/super_admin/get_category_or_sub_category?type=1"
    );
    yield put(fetchCategoriesSuccess(response.data));
  } catch (err) {
    yield put(fetchCategoriesFailure(err.error));
  }
}

function* watchFetchCategory() {
  yield takeEvery(INIT_FETCH_CATEGORIES, fetchCategories);
}

function* fetchCategoriesSaga() {
  yield all([fork(watchFetchCategory)]);
}

export default fetchCategoriesSaga;
