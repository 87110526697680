import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/registerSubAdmin" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t("Sub Admins")}</span>
              </Link>
            </li>
            
            <li>
              <Link to="/iletsBatch" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t("IELTS Batches")}</span>
              </Link>
            </li>

{/* 
            <li>
              <Link to="/csvUpload" className="waves-effect">
                <i className="ri-bar-chart-line"></i>
                <span className="ml-1">{this.props.t("CSV Uploads")}</span>
              </Link>
            </li>

            <li>
              <Link to="/pendingRequests" className="waves-effect">
                <i className="ri-bar-chart-line"></i>
                <span className="ml-1">{this.props.t("Pending Request")}</span>
              </Link>
            </li>

            <li>
              <Link to="/enrolledStudents" className="waves-effect">
                <i className="ri-account-circle-line"></i>
                <span className="ml-1">
                  {this.props.t("Enrolled Students")}
                </span>
              </Link>
            </li>

            <li>
              <Link to="/enrolledMentors" className="waves-effect">
                <i className="ri-pages-line"></i>
                <span className="ml-1">{this.props.t("Enrolled Mentors")}</span>
              </Link>
            </li>

            <li>
              <Link to="/enrolledMsme" className="waves-effect">
                <i className="ri-building-4-fill"></i>
                <span className="ml-1">{this.props.t("Enrolled MSME's")}</span>
              </Link>
            </li>

            <li>
              <Link to="/requestlor" className="waves-effect">
                <i className="ri-notification-2-line"></i>
                <span className="ml-1">
                  {this.props.t("Create/View Notifications")}
                </span>
              </Link>
            </li>

            <li>
              <Link to="/requestlor" className="waves-effect">
                <i className="ri-fingerprint-line"></i>
                <span className="ml-1">{this.props.t("Matching Engine")}</span>
              </Link>
            </li> */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className=" ri-speaker-line"></i>
                <span className="ml-1">{this.props.t("CRM")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/leadManagement">
                    {this.props.t("Lead Management")}
                  </Link>
                </li>
                <li>
                  <Link to="/createViewService">
                    {this.props.t("Create/View Services")}
                  </Link>
                </li>
                <li>
                  <Link to="/assignedServices">
                    {this.props.t("Assigned Services")}
                  </Link>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
