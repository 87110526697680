import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

const ViewDocs = () => {
  return (
    <>
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <h4 className="card-title" style={{ fontSize: "17px" }}>
                    Data
                  </h4>
                </Col>
                <Col md={6} style={{ textAlign: "right" }}>
                  <button className="btn btn-info">View</button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <CardBody>
              <Row>
                <Col md={8}>
                  <h4 className="card-title" style={{ fontSize: "17px" }}>
                    Survey Questionnaire
                  </h4>
                </Col>
                <Col md={4} style={{ textAlign: "right" }}>
                  <button className="btn btn-info">View</button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <Row>
                <Col md={8}>
                  <h4 className="card-title" style={{ fontSize: "17px" }}>
                    Interview Questions
                  </h4>
                </Col>
                <Col md={4} style={{ textAlign: "right" }}>
                  <button className="btn btn-info">View</button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <CardBody>
              <Row>
                <Col md={8}>
                  <h4 className="card-title" style={{ fontSize: "17px" }}>
                    Analysis & Findings
                  </h4>
                </Col>
                <Col md={4} style={{ textAlign: "right" }}>
                  <button className="btn btn-info">View</button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <Row>
                <Col md={8}>
                  <h4 className="card-title" style={{ fontSize: "17px" }}>
                    Case Report
                  </h4>
                </Col>
                <Col md={4} style={{ textAlign: "right" }}>
                  <button className="btn btn-info">View</button>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <button className="btn btn-success">Approve</button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <CardBody>
              <Row>
                <Col md={8}>
                  <h4 className="card-title" style={{ fontSize: "17px" }}>
                    Certifications
                  </h4>
                </Col>
                <Col md={4} style={{ textAlign: "right" }}>
                  <button className="btn btn-info">View</button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ViewDocs;
