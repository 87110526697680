import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication Module
import Account from "./auth/register/reducer";
import Login from "./auth/login/reducer";
import Forget from "./auth/forgetpwd/reducer";
import RegisterReducer from "./auth/subAdminRegister/reducer";
import UploadCsvReducer from "./uploadCsv/reducer";
import CategoryReducer from "./services/categories/reducer";
import serviceReducer from "./services/getServices/reducer";
import adminReducer from "./Admins/reducer";

const rootReducer = combineReducers({
  // public
  Layout,

  // Authentication
  Account,
  Login,
  Forget,
  RegisterReducer,
  UploadCsvReducer,
  CategoryReducer,
  serviceReducer,
  adminReducer,
});

export default rootReducer;
