import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { message } from "antd";
// Login Redux States
import { REGISTER_REQUEST_INIT } from "./actionTypes";
import { registerSuccess, registerInit, registerFailure } from "./actions";

// AUTH related methods

import { api_route } from "../../../Api-config/config";
import { registerSubAdmin } from "../../../helpers/ApiManager/subAdminRegister";

function* registerUser(user) {
  try {
    const response = yield call(registerSubAdmin, "/super_admin/add_role", {
      email: user.payload.email,
      name: user.payload.name,
      phone_number: user.payload.contact,
      profile_image: "ghansham.png",
      role: parseInt(user.payload.role),
    });
    message.success(response.message);

    yield put(registerSuccess(response));
  } catch (error) {
    yield put(registerFailure(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_REQUEST_INIT, registerUser);
}

function* loginSaga() {
  yield all([fork(watchUserRegister)]);
}

export default loginSaga;
