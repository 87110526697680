import React from "react";
import { Row, Col, Table } from "reactstrap";
import "./student.css";

const ViewProfile = (props) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <div className="profile-details-sec">
            <div className="student-img">
              <img src={props.data.studentImg}></img>
            </div>
            <h4>{props.data.studentName}</h4>
            <h5>{props.data.studentEmail}</h5>
            <h5>{props.data.studentPhn}</h5>
          </div>
          <div className="education-details">
            <Table bordered responsive>
              <tbody>
                <tr>
                  <td>
                    <b>10th Board Name</b>
                  </td>
                  <td>{props.data.tenthBoardName}</td>
                </tr>
                <tr>
                  <td>
                    <b>10th Marks</b>
                  </td>
                  <td>{props.data.tenthMarks}</td>
                </tr>
                <tr>
                  <td>
                    <b>12th/Diploma Board Name</b>
                  </td>
                  <td>{props.data.twelthBoardName}</td>
                </tr>
                <tr>
                  <td>
                    <b>12th/Diploma Marks</b>
                  </td>
                  <td>{props.data.twelthMarks}</td>
                </tr>
                <tr>
                  <td>
                    <b>UG Institution Name</b>
                  </td>
                  <td>{props.data.UGInstituteName}</td>
                </tr>
                <tr>
                  <td>
                    <b>UG Degree</b>
                  </td>
                  <td>{props.data.UGDegree}</td>
                </tr>
                <tr>
                  <td>
                    <b>UG Marks</b>
                  </td>
                  <td>{props.data.UGMarks}</td>
                </tr>
                <tr>
                  <td>
                    <b>PG Institution Name</b>
                  </td>
                  <td>{props.data.PGInstituteName}</td>
                </tr>
                <tr>
                  <td>
                    <b>PG Degree</b>
                  </td>
                  <td>{props.data.PGDegree}</td>
                </tr>
                <tr></tr>
                <tr>
                  <td>
                    <b>PG Marks</b>
                  </td>
                  <td>{props.data.PGMarks}</td>
                </tr>
                <tr>
                  <td>
                    <b>Interested Areas</b>
                  </td>
                  <td>{props.data.InterestedAreas}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ViewProfile;
