import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import NewLeads from "./NewLeads";
import PendingLeads from "./PendingLeads";
import ConvertedLeads from "./ConvertedLeads";
import RejectedManagement from "./RejectedManagement";
import { api_route } from "../../../Api-config/config";

class LeadManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_large: false,
      loaddata: [],
      breadcrumbItems: [
        { title: "EGS", link: "#" },
        { title: "Lead Management", link: "#" },
      ],
    };
  }

  //  url = "http://127.0.0.1:80/api";

  // const [loaddata, setloaddata] = useState(
  // );

  getLeads = () => {
    const get = {
      method: "GET",
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    };
    fetch(api_route + "/super_admin/get_leads", get)
      .then(async (res) => {
        if (!res.ok) {
          // error coming back from server
          throw Error("could not fetch the data for that resource");
        } else {
          var resjason = await res.json();
          //console.log(resjason);
          this.setState({ loaddata: resjason });
        }
      })

      .catch((err) => {
        //                         // auto catches network / connection error
        //                         setIsPending(false);
        //                         setError(err.message);
        //console.log(err);
      });
  };

  componentDidMount() {
    this.getLeads();
    // window.location.reload(true);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Lead Management"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <div className="students-heading">
                  <h4>New Leads</h4>
                </div>
                <Card>
                  <CardBody>
                    <NewLeads getdata={this.state.loaddata} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="students-heading">
                  <h4>Pending Leads</h4>
                </div>
                <Card>
                  <CardBody>
                    <PendingLeads getPendingData={this.state.loaddata} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="students-heading">
                  <h4>Converted Leads</h4>
                </div>
                <Card>
                  <CardBody>
                    <ConvertedLeads getConvertedData={this.state.loaddata} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="students-heading">
                  <h4>Rejected Leads</h4>
                </div>
                <Card>
                  <CardBody>
                    <RejectedManagement getRejectedData={this.state.loaddata} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default LeadManagement;
