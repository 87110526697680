import React, { useEffect, useState } from "react";

import { Table, Row, Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { api_route } from "../../../Api-config/config";
import { useHistory } from "react-router-dom";

const PendingLeads = (props) => {
  const loaddata = props.getPendingData;
  // const reversedata = (loaddata.data).reverse();

  // console.log("th is is vere =========",reversedata);

  const history = useHistory();

  const [status, setstatus] = useState("");
  const [assigned_to, setassigned_to] = useState();
  const [rem1, setrem1] = useState(" ");
  const [rem2, setrem2] = useState(" ");

  // useEffect(() => { }, [loaddata.data]);

  // const loaddataArr= loaddata.data;
  // const filterData = loaddata.data.filter(item => item.status === 0 )

  // //console.log('====================================');
  // //console.log(loaddata);
  // //console.log('====================================');

  //   useEffect(()=>{

  //     var arr1 = loaddata.data;
  //     var filterdata = arr1.filter(item => item.status === 0 && item.assigned_to=== null )
  //     // // loaddata.filter(item => item.status !== "Converted");
  //     //console.log(filterdata.data);

  // });

  const categoryOption = [
    {
      options: [
        { label: "Student", value: "Student" },
        // { label: "Mentor", value: "Mentor" },
        // { label: "MSME", value: "MSME" },
      ],
    },
  ];

  const serviceOption = [
    {
      options: [
        { label: "Admission", value: "Admission" },
        { label: "Course", value: "Course" },
      ],
    },
  ];

  const saveApiCall = (id) => {
    const data = {
      // updatedata
      lead_id: id,
      authority_id: 1,
      status: status,
      remark1: rem1,
      remark2: rem2,
      remark3: "default",
      revenue: "0",
    };

    // //console.log('====================================');
    // //console.log(data);
    // //console.log('====================================');

    fetch(api_route + "/super_admin/assign_lead", {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "access-token": localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          // window.alert("Invalid REgisteration ");
          alert("Changes Saved");
          window.location.reload(true);
          history.push("/leadManagement/");
          // history.push("/dashboard");
        } else {
          alert("Some Error Occured");
        }
        return response.json();
      })
      .then(
        (json) => {
          // //console.log(json)
          // setUpdatedata();
          // this.setState({
          // 	user:json
          // });
        }
        // var history = useHistory();
      );
  };

  const auth_name = {
    0: "None",
    1: "Dr. Piyush Bhardwaj",
    2: "Dr. Adarsh Bhardwaj",
    3: "Mr. Bikash Sinha",
    4: "Mr. Yash Sharma",
    5: "Ms. Monimala",
    6: "Ms. Shubha Sharma",
    7: "Mr. B.P. Arora",
  };

  return (
    <>
      <div className="filtered-fields">
        <Row>
          <Col md={8}>
            <Row>
              <Col md={4}>
                <FormGroup className="select2-container">
                  <Label>Select Category</Label>
                  <Select
                    options={categoryOption}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className="select2-container">
                  <Label>Select Service</Label>
                  <Select
                    options={serviceOption}
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Country</th>
            <th>E-Mail</th>
            <th>Phone No</th>
            <th>Service</th>
            <th>Status</th>
            <th>Assigned To</th>
            <th>Remark 1</th>
            <th>Remark 2</th>
            {/* <th>Remark 3</th> */}
          </tr>
        </thead>
        <tbody>
          {loaddata.data &&
            loaddata.data.slice(0).reverse().map(
              (loaditem, index) =>
                loaditem.status === 1 &&
                <tr>
                  <td>{loaditem.id}</td>
                  <td>{loaditem.user_name}</td>
                  <td>{loaditem.country}</td>
                  <td>{loaditem.user_email}</td>
                  <td>{loaditem.user_phone_number}</td>
                  <td>{loaditem.service_name}</td>
                  <td>
                    <select
                      name="status"
                      // id="status"
                      onChange={(e) => setstatus(e.target.value)}
                    >
                      <option value="1">Pending</option>
                      <option value="0">NewLead</option>
                      <option value="2">Rejected</option>
                      <option value="3">Converted</option>
                    </select>
                    {/* <select
                        name="status"
                        onChange={(e) => setstatus(e.target.value)}
                      >
                        <option value="0">NewLead</option>
                        <option value="1">Pending</option>
                        <option value="2">Rejected</option>
                        <option value="3">Converted</option>
                      </select> */}

                  </td>
                  <td>{auth_name[loaditem.assigned_to]}</td>
                  <td>
                    <input
                      defaultValue={loaditem.remark1}
                      onChange={(e) => setrem1(e.target.value)}
                    />
                  </td>

                  {/* <td contentEditable="true" onChange={(e) => setrem2(e.target.value)} > */}
                  <td>
                    <input
                      defaultValue={(loaditem.remark2)}
                      onChange={(e) => setrem2(e.target.value)}
                    />
                  </td>

                  {/* <td   >
                  <input   Value={loaditem.remark3}  onChange={(e) => setrem3(e.target.value)} />
                  
                  </td> */}
                  <td>
                    <button onClick={() => saveApiCall(loaditem.id)}
                    >
                      Save
                    </button>
                  </td>
                </tr>

            )}
        </tbody>
      </Table>
    </>
  );
};

export default PendingLeads;
