import { api_route } from "../../Api-config/config";
import { Form, notification } from "antd";

const registerSubAdmin = async (url, data) => {
  try {
    const response = await fetch(api_route + url, {
      method: "POST",
      headers: {
        "access-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (response.status >= 200 && response.status <= 299) {
      const res = await response.json();
      return res;
    }
    throw response;
  } catch (err) {
    var message;
    if (err && err.status) {
      switch (err.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          const res = await err.json();
          message = res.error;
          break;
      }
    }

    notification.error({ message: "Error", description: message });

    throw message;
  }
};

const csvUpload = async (url, data) => {
  console.log(data);

  try {
    var formData = new FormData();
    formData.append(`${Object.keys(data)[0]}`, data[`${Object.keys(data)[0]}`]);
    const response = await fetch(api_route + url, {
      method: "POST",
      headers: {
        "access-token": localStorage.getItem("token"),
      },
      body: formData,
    });
    if (response.status >= 200 && response.status <= 299) {
      const res = await response.json();
      return res;
    }
    throw response;
  } catch (err) {
    var message;
    if (err && err.status) {
      switch (err.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          const res = await err.json();
          message = res.error;
          break;
      }
    }

    notification.error({ message: "Error", description: message });

    throw message;
  }
};

export { registerSubAdmin, csvUpload };
