import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import EnrolledMsmeTable from "./EnrolledMsmeTable";
import MsmeDetails from "./MsmeDetails";
import StudentsUnderMsme from "./StudentsUnderMsme";

class EnrolledMsme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_large: false,
      modal_internship: false,
      modal_profile: false,
      breadcrumbItems: [
        { title: "EGS", link: "#" },
        { title: "Enrolled MSME's", link: "#" },
      ],
    };
    this.tog_large = this.tog_large.bind(this);
    this.tog_internship = this.tog_internship.bind(this);
    this.tog_profile = this.tog_profile.bind(this);
  }

  tog_internship() {
    this.setState((prevState) => ({
      modal_internship: !prevState.modal_internship,
    }));
    this.removeBodyCss();
  }

  tog_large() {
    this.setState((prevState) => ({
      modal_large: !prevState.modal_large,
    }));
    this.removeBodyCss();
  }

  tog_profile() {
    this.setState((prevState) => ({
      modal_profile: !prevState.modal_profile,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }
  render() {
    const msmeData = [
      {
        id: 1,
        msmeName: "Rakesh Sharma",
        msmeEmail: "Amrit@gmail.com",
        msmePhn: 9865986598,
        msmeImg: require("../../assets/images/users/avatar-2.jpg"),
        UGDegree: "B.Tech",
        PGDegree: "MBA",
        currentCompany: "TCS",
        totalExp: 5,
        InterestedAreas:
          "Business, Technology, Marketing, Data Science, Supply Chain Management",
        viewDetails: (
          <button onClick={this.tog_internship} className="btn btn-success">
            View
          </button>
        ),
        enrolledStudents: (
          <button onClick={this.tog_large} className="btn btn-success">
            View
          </button>
        ),
      },
    ];

    const studentsUnderMsme = [
      {
        id: 1,
        studentName: "Rohan Sharma",
        studentEmail: "rohan@gmail.com",
        studentPhn: 9868956265,
        interestedAreas:
          "Business, Marketing, Digital Marketing, Finance, Operations",
      },
      {
        id: 2,
        studentName: "Rohan Sharma",
        studentEmail: "rohan@gmail.com",
        studentPhn: 9868956265,
        interestedAreas:
          "Business, Marketing, Digital Marketing, Finance, Operations",
      },
      {
        id: 3,
        studentName: "Rohan Sharma",
        studentEmail: "rohan@gmail.com",
        studentPhn: 9868956265,
        interestedAreas:
          "Business, Marketing, Digital Marketing, Finance, Operations",
      },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Enrolled MSME's"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <EnrolledMsmeTable data={msmeData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Internship Details Modal Starts */}
            <Modal
              size="lg"
              isOpen={this.state.modal_internship}
              toggle={this.tog_internship}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_internship: false })}
              >
                Mentor Details
              </ModalHeader>
              <ModalBody>
                <MsmeDetails data={msmeData[0]}/>
              </ModalBody>
            </Modal>
            {/* Internship Detail Modal Ends */}

            {/* Documents Modal */}
            <Modal
              size="lg"
              isOpen={this.state.modal_large}
              toggle={this.tog_large}
            >
              <ModalHeader toggle={() => this.setState({ modal_large: false })}>
                Enrolled Students
              </ModalHeader>
              <ModalBody>
                <StudentsUnderMsme data={studentsUnderMsme}/>
              </ModalBody>
            </Modal>
            {/* Document Modal Ends */}

            {/* Profile Modal Starts */}
            <Modal
              isOpen={this.state.modal_profile}
              toggle={this.tog_profile}
              scrollable={true}
            >
              <ModalHeader
                toggle={() => this.setState({ modal_profile: false })}
              >
                Student Profile
              </ModalHeader>
              <ModalBody></ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EnrolledMsme;
