import {
  INIT_FETCH_SERVICES,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
} from "./actionTypes";
import { put, fork, all, call, takeEvery } from "redux-saga/effects";
import { getServicesAPI } from "../../../helpers/ApiManager/services";
import { fetchServicesFailure, fetchServicesSuccess } from "./action";

function* fetchServices() {
  try {
    const response = yield call(getServicesAPI, "/super_admin/get_services");
    yield put(fetchServicesSuccess(response.data));
  } catch (err) {
    yield put(fetchServicesFailure(err));
  }
}

function* watchFetchService() {
  yield takeEvery(INIT_FETCH_SERVICES, fetchServices);
}

function* FetchServiceSaga() {
  yield all([fork(watchFetchService)]);
}

export default FetchServiceSaga;
