import React from "react";
import { Row, Col, Table } from "reactstrap";
import "./mentor.css";

const MentorDetails = (props) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <div className="profile-details-sec">
            <div className="student-img">
              <img src={props.data.mentorImg}></img>
            </div>
            <h4>{props.data.mentorName}</h4>
            <h5>{props.data.mentorEmail}</h5>
            <h5>{props.data.mentorPhn}</h5>
          </div>
          <div className="education-details">
            <Table bordered responsive>
              <tbody>
                <tr>
                  <td>
                    <b>UG Degree</b>
                  </td>
                  <td>{props.data.UGDegree}</td>
                </tr>
                <tr>
                  <td>
                    <b>PG Degree</b>
                  </td>
                  <td>{props.data.PGDegree}</td>
                </tr>
                <tr>
                  <td>
                    <b>Current Company</b>
                  </td>
                  <td>{props.data.currentCompany}</td>
                </tr>
                <tr>
                  <td>
                    <b>Total Experience</b>
                  </td>
                  <td>{props.data.totalExp} Years</td>
                </tr>
                <tr>
                  <td>
                    <b>Interested Areas</b>
                  </td>
                  <td>{props.data.InterestedAreas}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MentorDetails;
